// usersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [], // Initial state - empty list of users
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.usersList = action.payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;
export default usersSlice.reducer;
