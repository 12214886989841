import React, { useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BigButton from "./BigButton";
import { Alert, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm, Controller, useWatch } from "react-hook-form";
import wretch from "wretch";
import { set } from "date-fns";
import { updateAuth } from "./store/slices/authSlice";
import { useDispatch } from "react-redux";
import VerificationInput from "react-verification-input";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useGoogleLogin } from "@react-oauth/google";
import { APPLE_LOGIN_LINK, API_URL } from "./consts";
import Cookies from "js-cookie";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import logo from "./assets/logo.svg";
import shadow from "./assets/shadow.png";
import { useParams, useNavigate } from "react-router-dom";
const Center = ({ children, ...props }) => {
  return (
    <p style={{ textAlign: "center", marginBottom: 0 }} {...props}>
      {children}
    </p>
  );
};

const Auth = ({ msg }) => {
  let { iv, content, data, resetPasswordHash, resetPasswordEmail } =
    useParams();
  const navigate = useNavigate();
  const isLinkAccessFlow = iv && content;
  const isQrcodeFlow = data;
  const isResetPasswordFlow = resetPasswordHash && resetPasswordEmail;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [authMode, setAuthMode] = useState(
    isResetPasswordFlow ? "register" : "login"
  );
  const [phoneVerificationMode, setPhoneVerificationMode] = useState(false);
  const isLogin = authMode === "login";
  const isRegister = authMode === "register";
  const windowHeight = useSelector((state) => state.ui.windowHeight);
  const [message, setMessage] = useState({ isError: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState();
  const [registerMode, setRegisterMode] = useState(
    isResetPasswordFlow ? "email" : "init"
  );
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const clearMessage = () => setMessage({ isError: false, message: "" });

  const { control, handleSubmit, setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      emailAddress: "",
      password: "",
      confirmPassword: "",
      select: {},
    },
  });

  const SensioAir = () => {
    return (
      <>
        <div style={{ height: 22 }} />
        <div
          style={{
            left: 0,
            position: "absolute",
            width: "100%",
            color: "#3337ED",
            textAlign: "center",
            fontSize: 22,
            lineHeight: "22px",
            position: "absolute",
            bottom: 20,
          }}
          className=""
        >
          Sensio Air
        </div>
      </>
    );
  };

  const googleLogin = () => window.open(`${API_URL}/auth/google`, "_self");

  const googleLoginOld = useGoogleLogin({
    // ux_mode: 'redirect',
    onSuccess: (res) => {
      if (res?.access_token) handleLogin(null, "google", res?.access_token);
      else {
        setMessage({ isError: true, message: "Google login failed" });
      }

      setMessage({ isError: false, message: "" });
    },
    onerror: (e) => {
      setMessage({ isError: true, message: `Google login failed` });
    },
  });

  React.useEffect(() => {
    handleResponse(msg);
  }, [msg]);

  React.useEffect(() => {
    if (isLinkAccessFlow) {
      handleLinkAccess();
    }
    // if (isQrcodeFlow) {
    //   const checkAuth = async () => {
    //     try {
    //       const res = await wretch(API_URL + "/auth/check")
    //         .options({ withCredentials: true, credentials: "include" })
    //         .get()
    //         .res(async (response) => {
    //           if (response.ok) {
    //             const json = await response.json();
    //             if (!json?.error && json?.user) {
    //               console.log(json);
    //               // dispatch(updateAuth(true));
    //               // dispatch(updateUser(json?.user));
    //             }
    //           }
    //         });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    //   checkAuth();
    // }
  }, []);

  React.useEffect(() => {
    const adjustHeight = () => {
      window.scrollTo(0, 0);
    };
    document.addEventListener("focusout", adjustHeight);
  }, []);

  const handleError = (error) => {
    if (error?.message == "Failed to fetch") {
      setMessage({ isError: true, message: "Connection error" });
    } else {
      setMessage({ isError: true, message: error?.json?.message ?? "Error" });
    }
    setLoading(false);
  };

  const handleResponse = (res) => {
    let isError = true;
    if (res?.error === false) isError = false;

    if (
      res?.message == "Please verify your phone number" ||
      res?.message == "Email successfully verified" ||
      res?.message == "Email already verified"
    ) {
      setPhoneVerificationMode(true);
      setMessage({ isError, message: "Please verify your phone number" });
    } else {
      if (res) setMessage({ isError, message: res?.message });
    }
    if (res?.message == "Password has been changed") {
      setValue("emailAddress", resetPasswordEmail);
      setValue("password", "");
      setAuthMode("login");
      navigate(`/auth`);
    }
    setLoading(false);
    if (res?.email) {
      setValue("emailAddress", res?.email);
    }
  };

  React.useEffect(() => {
    //apple auth error
    const getMsgFromCookie = () => {
      try {
        const c = Cookies.get();
        return c?.msg?.toString().split(":")[1].split(".")[0];
      } catch (e) {
        return null;
      }
    };
    const cookieMsg = getMsgFromCookie();
    if (cookieMsg) {
      //apple
      const _res = { isError: true, message: cookieMsg };
      handleResponse(_res);
      Cookies.remove("msg");
    }
  }, []);

  const handleLinkAccess = async () => {
    clearMessage();
    setLoading(true);
    const res = await wretch(API_URL + `/auth/link/${iv}/${content}`)
      .options({
        credentials: "include",
      })
      .post({})
      .res(async (res) => {
        res = await res?.json();
        handleResponse(res);
        if (res?.error === false) {
          dispatch(updateAuth(true));
        }
      })
      .catch(handleError);
  };

  const handleSendForgotPasswordLink = async (formData) => {
    clearMessage();
    setLoading(true);
    const res = await wretch(API_URL + `/auth/reset-password`)
      .post({ email: formData.emailAddress })
      .res(async (res) => {
        res = await res?.json();
        handleResponse(res);
      })
      .catch(handleError);
  };

  const handleLogin = async (formData, provider, token) => {
    clearMessage();
    setLoading(true);
    const res = await wretch(
      API_URL +
        "/auth/login" +
        (provider && token ? `/${provider}/${token}` : "")
    )
      .options({
        credentials: "include",
      })
      .post({
        username: formData?.emailAddress?.toLowerCase(),
        password: formData?.password,
      })
      .res(async (res) => {
        res = await res?.json();
        handleResponse(res);
        if (res?.error === false) {
          Cookies.remove("phoneCode");
          dispatch(updateAuth(true));
        }
      })
      .catch(handleError);
  };

  const handleChangePassword = async (formData) => {
    clearMessage();
    setLoading(true);

    const res = await wretch(API_URL + "/auth/set-password")
      .post({
        email: resetPasswordEmail,
        newPassword: formData.password,
        hash: resetPasswordHash,
      })
      .res(async (response) => {
        handleResponse(await response?.json());
      })
      .catch(handleError);
  };

  const handleRegister = async (formData) => {
    setLoading(true);
    clearMessage();
    const res = await wretch(API_URL + "/auth/register")
      .post({
        email: formData.emailAddress.toLowerCase(),
        password: formData.password,
      })
      .res(async (response) => {
        handleResponse(await response?.json());
      })
      .catch(handleError);
  };

  const Message = ({}) => {
    return (
      <Piece>
        <p
          style={{
            marginBottom: 0,
            fontSize: 16,
            color: message.isError ? "red" : "green",
          }}
        >
          {message.message}
        </p>
      </Piece>
    );
  };

  const SwitchFlow = ({ getBack = false }) => {
    return (
      <>
        <Piece>
          <Center
            onClick={() => {
              clearMessage();
              reset();
              if (phoneVerificationMode) {
                setPhoneVerificationMode(false);
                setCodeSent(false);
                setCurrentPhoneNumber(undefined);
              } else if (getBack && isResetPasswordFlow) {
                setAuthMode("login");
                navigate(`/auth`);
              } else if (getBack && forgotPasswordMode) {
                setForgotPasswordMode(false);
              } else if (getBack && registerMode === "email") {
                setRegisterMode("init");
              } else {
                setAuthMode(getBack || isLogin ? "register" : "login");
              }
            }}
          >
            <Link style={{ fontSize: 16 }}>
              {getBack ? "Get Back" : isLogin ? "Sign Up" : "Sign In"}
            </Link>
          </Center>
          {(message?.message ==
            "A user with the given username is already registered" ||
            (!forgotPasswordMode && !isResetPasswordFlow && isLogin)) && (
            <Center
              onClick={() => {
                clearMessage();
                setForgotPasswordMode(true);
              }}
            >
              <Link style={{ fontSize: 16 }}>Forgot password?</Link>
            </Center>
          )}
        </Piece>
        {/* <Piece>
          <p
            style={{
              marginBottom: 0,
              color: message.isError ? "red" : "green",
            }}
          >
            {message.message}
          </p>
        </Piece> */}
        <Message />
      </>
    );
  };

  // add missing rules for validaiton

  const PhoneNumberVerification = ({ currentPhoneNumber, isQrcodeFlow }) => {
    const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber);

    const validatePhoneNumber = async (phoneField) => {
      clearMessage();
      setCurrentPhoneNumber(phoneField);
      if (!phoneField || phoneField?.replace(/\D/g, "")?.length < 9) {
        return handleError({ json: { message: "Invalid phone number" } });
      }
      const formData = getValues();
      setLoading(true);
      try {
        const payload = {
          username: isQrcodeFlow
            ? undefined
            : formData.emailAddress.toLowerCase(),
          password: isQrcodeFlow ? undefined : formData.password,
          phoneNumber: phoneField,
          data: isQrcodeFlow ? data : undefined,
        };
        const res = await wretch(
          API_URL + "/auth/" + (isQrcodeFlow ? "getAccessV2" : "verifyphone")
        )
          .options({ withCredentials: true, credentials: "include" })
          .post(payload)
          .res(async (response) => {
            const jsonResp = await response?.json();
            setCodeSent(JSON.stringify(jsonResp, null, 2));
            handleResponse(jsonResp);
          })
          .catch(handleError);
      } catch (error) {
        console.log(error);
      }
    };

    const handleCodeChange = async (code) => {
      setLoading(true);
      const formData = getValues();
      clearMessage();
      let payload = {
        code,
        username: formData.emailAddress.toLowerCase(),
        password: formData.password,
        phoneNumber,
      };
      const res = await wretch(API_URL + "/auth/verifyphonecode")
        .options({ withCredentials: true, credentials: "include" })
        .post(payload)
        .res(async (response) => {
          response = await response?.json();
          if (response?.error === false) {
            dispatch(updateAuth(true));
          }
          handleResponse(response);
        })
        .catch(handleError);
    };

    return (
      <>
        <Piece>
          <PhoneInput
            value={phoneNumber}
            onChange={setPhoneNumber}
            // onBlur={(x) => validatePhoneNumber(x.target.value)}
            country={"us"}
            containerClass={"phoneInputContainer"}
            disabled={codeSent}
          />
        </Piece>
        {isQrcodeFlow ? null : (
          <Piece>
            <VerificationInput
              containerProps={{
                style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: codeSent ? 1 : 0.3,
                  height: 40,
                },
              }}
              length={4}
              validChars={"0-9"}
              onComplete={handleCodeChange}
              inputProps={{
                type: "tel",
                autoComplete: "one-time-code",
                disabled: !codeSent,
                autoFocus: true,
                autoFocus: codeSent,
              }}
            />
          </Piece>
        )}
        <Piece>
          <BigButton
            loading={loading}
            disabled={!!codeSent}
            backgroundColor={"#4059D4"}
            height={40}
            title={isQrcodeFlow ? "Get link" : "Get Code"}
            onClick={() => validatePhoneNumber(phoneNumber)}
            borderRadius={9}
            icon={<ArrowForwardIcon />}
            width={"100%"}
          />
        </Piece>
        {isQrcodeFlow ? null : (
          <>
            <SwitchFlow getBack={true} /> <Piece /> <Piece />
          </>
        )}
      </>
    );
  };

  const Piece = ({ size = 1, children, style }) => {
    return (
      <div
        style={{
          height: 55 * size,
          width: 300,
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          // border: "1px lightgrey solid",
          textAlign: "center",
          alignItems: "center",
          ...style,
        }}
      >
        {children ?? ""}
      </div>
    );
  };

  const LoginFlow = ({}) => {
    return (
      <>
        <Piece>
          <Controller
            name="emailAddress"
            rules={{
              required: "this field is required",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "invalid email address",
              },
            }}
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
              formState,
            }) => (
              <TextField
                size="small"
                InputProps={{
                  type: "email",
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "your-email",
                }}
                error={!!error}
                onChange={onChange}
                value={value}
                label={error ? error?.message : "Email address"}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder=""
              />
            )}
          />
        </Piece>
        <Piece>
          <Controller
            defaultValue={""}
            name="password"
            rules={{
              required: "this field is required",
            }}
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
              formState,
            }) => (
              <TextField
                size="small"
                InputProps={{
                  type: "password",
                  autoComplete: "your-password",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!error}
                onChange={onChange}
                value={value}
                label={error ? error?.message : "Password"}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder=""
              />
            )}
          />
        </Piece>
        <Piece>
          <BigButton
            loading={loading}
            // disabled={codeSent}
            backgroundColor={"#4059D4"}
            height={40}
            title={"Sign In"}
            onClick={handleSubmit(handleLogin)}
            // onClick={() => setPhoneVerificationMode(!phoneVerificationMode)}
            borderRadius={9}
            icon={<ArrowForwardIcon />}
            width={"100%"}
          />
        </Piece>
        <SwitchFlow />
        <Piece>
          <BigButton
            loading={loading}
            // disabled={codeSent}
            backgroundColor={"#4059D4"}
            height={40}
            title={"Sign in with Google"}
            onClick={googleLogin}
            borderRadius={9}
            icon={<ArrowForwardIcon />}
            width={"100%"}
          />
        </Piece>
        <Piece>
          <BigButton
            loading={loading}
            // disabled={codeSent}
            backgroundColor={"#4059D4"}
            height={40}
            title={"Sign in with Apple"}
            onClick={() => {
              window.location.href = APPLE_LOGIN_LINK;
            }}
            borderRadius={9}
            icon={<ArrowForwardIcon />}
            width={"100%"}
          />
        </Piece>
      </>
    );
  };

  const ForgotPasswordFlow = ({}) => {
    return (
      <>
        <Piece>
          <Controller
            name="emailAddress"
            rules={{
              required: "this field is required",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "invalid email address",
              },
            }}
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
              formState,
            }) => (
              <TextField
                size="small"
                InputProps={{
                  type: "email",
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "your-email",
                }}
                error={!!error}
                onChange={onChange}
                value={value}
                label={error ? error?.message : "Email address"}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder=""
              />
            )}
          />
        </Piece>

        <Piece>
          <BigButton
            loading={loading}
            // disabled={mailSent}
            backgroundColor={"#4059D4"}
            height={40}
            title={"Send reset link"}
            // ###
            onClick={handleSubmit(handleSendForgotPasswordLink)}
            borderRadius={9}
            icon={<ArrowForwardIcon />}
            width={"100%"}
          />
        </Piece>
        <SwitchFlow getBack={true} />
      </>
    );
  };
  const RegisterFlow = ({}) => {
    // const est = useWatch({ name: "password", control: "password" });
    // const dest = useWatch({ name: "emailAddress" });
    const passwordFieldValue = useWatch({
      control,
      name: "password",
      defaultValue: "",
    });

    return (
      <>
        {registerMode === "email" && (
          <>
            <Piece>
              <Controller
                name="emailAddress"
                rules={
                  isResetPasswordFlow
                    ? {}
                    : {
                        required: "this field is required",
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "invalid email address",
                        },
                      }
                }
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <TextField
                    size="small"
                    InputProps={{
                      type: "email",
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    onChange={onChange}
                    disabled={!!isResetPasswordFlow}
                    value={isResetPasswordFlow ? resetPasswordEmail : value}
                    label={error ? error?.message : "Email address"}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Piece>
            <Piece>
              <Controller
                defaultValue={""}
                name="password"
                rules={{
                  required: "this field is required",
                  minLength: {
                    value: 8,
                    message: "password must be at least 8 characters long",
                  },
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <TextField
                    size="small"
                    InputProps={{
                      type: "password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    label={error ? error?.message : "Password"}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Piece>
            <Piece>
              <Controller
                defaultValue={""}
                name="confirmPassword"
                rules={{
                  required: "this field is required",
                  minLength: {
                    value: 8,
                    message: "password must be at least 8 characters long",
                  },
                  validate: (value) =>
                    value === passwordFieldValue || "passwords do not match",
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <TextField
                    size="small"
                    InputProps={{
                      type: "password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    label={error ? error?.message : "Confirm password"}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Piece>
            <Piece>
              <BigButton
                loading={loading}
                // disabled={codeSent}
                backgroundColor={"#4059D4"}
                height={40}
                title={isResetPasswordFlow ? "Set new password" : "Sign Up"}
                onClick={handleSubmit(
                  isResetPasswordFlow ? handleChangePassword : handleRegister
                )}
                borderRadius={9}
                icon={<ArrowForwardIcon />}
                width={"100%"}
              />
            </Piece>
          </>
        )}
        {registerMode === "init" && (
          <>
            <Piece>
              <BigButton
                loading={loading}
                // disabled={codeSent}
                backgroundColor={"#4059D4"}
                height={40}
                title={"Sign Up with email"}
                onClick={() => setRegisterMode("email")}
                borderRadius={9}
                icon={<ArrowForwardIcon />}
                width={"100%"}
              />
            </Piece>
            <Piece>
              <BigButton
                loading={loading}
                // disabled={codeSent}
                backgroundColor={"#4059D4"}
                height={40}
                title={"Sign Up with Google"}
                onClick={googleLogin}
                borderRadius={9}
                icon={<ArrowForwardIcon />}
                width={"100%"}
              />
            </Piece>
            <Piece>
              <BigButton
                loading={loading}
                // disabled={codeSent}
                backgroundColor={"#4059D4"}
                height={40}
                title={"Sign Up with Apple"}
                onClick={() => (window.location.href = APPLE_LOGIN_LINK)}
                borderRadius={9}
                icon={<ArrowForwardIcon />}
                width={"100%"}
              />
            </Piece>
          </>
        )}

        <SwitchFlow getBack={registerMode === "email"} />
        <Piece />
        {registerMode === "init" && <Piece />}
      </>
    );
  };

  const Header = () => {
    return (
      <>
        <img src={logo} alt="" style={{ height: 99, width: 99 }} />
        <img src={shadow} alt="" />
        <span style={{ fontSize: 15, color: "#4A4A4A", marginBottom: 50 }}>
          Airborne Pathogen Tracking
        </span>
      </>
    );
  };

  if (isAuthenticated && !isLinkAccessFlow) console.log("redirecting");
  if (isAuthenticated && !isLinkAccessFlow)
    return (
      <Navigate
        replace
        to={"/data" + (isQrcodeFlow ? `/${isQrcodeFlow}` : ``)}
      />
    );
  // if (isAuthenticated) return <Navigate replace to="/data" />;
  return (
    <div
      style={{
        height: windowHeight,
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        top: 0,
        alignItems: "center",
        border: "1px black solid",
      }}
    >
      <Header />
      {
        // isQrcodeFlow ? (
        //   "validate link flow"
        // ) :
        isLinkAccessFlow ? (
          <>
            <Message />
          </>
        ) : forgotPasswordMode ? (
          <ForgotPasswordFlow />
        ) : phoneVerificationMode || isQrcodeFlow ? (
          <PhoneNumberVerification
            currentPhoneNumber={currentPhoneNumber}
            isQrcodeFlow={isQrcodeFlow}
          />
        ) : authMode === "login" ? (
          <LoginFlow />
        ) : (
          <RegisterFlow />
        )
      }
      {isQrcodeFlow ? <Message /> : null}
      <SensioAir />
    </div>
  );
};

export default Auth;
