import { createSlice } from "@reduxjs/toolkit";

export const iotDataSlice = createSlice({
  name: "iotData",
  initialState: {
    value: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchIotDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchIotDataSuccess: (state, action) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchIotDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchIotDataStart, fetchIotDataSuccess, fetchIotDataFailure } =
  iotDataSlice.actions;

export const selectIotData = (state) => state.iotData.value;

export default iotDataSlice.reducer;
