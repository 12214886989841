import { createAsyncThunk } from "@reduxjs/toolkit";
import { useWretchPost } from "../../hooks/useWretchPost";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch }) => {
    try {
      // Using a hook to get a Wretch instance
      const post = useWretchPost(dispatch);

      const response = await post("/auth/login", {
        username: email?.toLowerCase(),
        password: password,
      });

      if (response.error === false) {
        // Save the token to localStorage
        localStorage.setItem("token", response.token);

        return response;
      } else if (response?.error && response?.message) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);
