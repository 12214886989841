import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import { SLoader } from "./components/sLoader/sLoader";
import Login from "./Login";
import { useWretchGet } from "./hooks/useWretchGet";
import { useDispatch } from "react-redux";
import Auth from "./Auth";

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const get = useWretchGet(dispatch);
  let { userId, verificationCode } = useParams();
  const [json, setJson] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonResponse = await get(
          `/auth/verifyemail/${userId}/${verificationCode}`
        );
        setJson(jsonResponse); // Update status with received JSON
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (json) return <Auth msg={json} />;
  else return <SLoader />;
}
