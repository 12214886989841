import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  authError: undefined,
  userType: "user",
  userDevices: [],
  networkError: null,
  verifyPhone: true,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateAuthError: (state, action) => {
      state.authError = action.payload.authError;
    },
    updateNetworkError: (state, action) => {
      state.networkError = action.payload.networkError;
    },
    updateUserType: (state, action) => {
      state.userType = action.payload.userType;
    },
    updateUserAllowedRooms: (state, action) => {
      state.userDevices = action.payload.userDevices;
    },
    updateVerifyPhone: (state, action) => {
      state.verifyPhone = action.payload.verifyPhone;
    },
  },
});

export const {
  updateAuth,
  updateAuthError,
  updateNetworkError,
  updateUser,
  updateUserType,
  updateUserAllowedRooms,
  updateVerifyPhone,
} = authSlice.actions;

export default authSlice.reducer;
