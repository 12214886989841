import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useWretchPost } from "../hooks/useWretchPost";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

export default function AddDeviceModal({
  children,
  triggerDevicesReload,
  enabled,
}) {
  const dispatch = useDispatch();
  const post = useWretchPost(dispatch);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const [loading, setLoading] = React.useState(undefined);
  const [message, setMessage] = React.useState(undefined);
  const [addDeviceCode, setAddDeviceCode] = React.useState(null);

  const addDevice = async (addDeviceCode) => {
    try {
      setLoading(true);
      const res = await post(`/device/add`, {
        deviceSerial: addDeviceCode,
      });

      if (res?.error && res?.message) {
        setError(res.message);
      } else if (res?.error === false) {
        setMessage(res?.message);
        triggerDevicesReload();
      }
      setLoading(false);
      return res;
    } catch (e) {
      setLoading(false);

      console.error(e);
    }
  };

  const handleClickOpen = () => {
    enabled && setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  const validateInput = (input) => {
    setError(null);
    setMessage(null);
    return input?.length === 6 && input?.match(/^[a-zA-Z0-9]{6}$/);
  };

  React.useEffect(() => {
    setError(null);
    setMessage(null);
  }, []);

  return (
    <React.Fragment>
      <div onClick={handleClickOpen}>{children}</div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            if (loading) return;
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            validateInput(formJson.deviceCode)
              ? addDevice(formJson.deviceCode)
              : setError("Invalid device code");
          },
        }}
      >
        <DialogContent>
          <>
            <DialogContentText>
              Please enter 6-digit device code
            </DialogContentText>
            <TextField
              disabled={loading || message}
              label="6-digit device code"
              autoFocus
              required
              margin="dense"
              id="deviceCode"
              name="deviceCode"
              fullWidth
              variant="standard"
              InputProps={{
                style: { textTransform: "uppercase" },
                maxLength: 6,
              }}
            />
            <span style={{ color: error ? "red" : "green" }}>
              {error}
              {message}
              {"\u00A0"}
            </span>
          </>
        </DialogContent>
        <DialogActions>
          {message ? (
            <Button onClick={handleClose}>Close</Button>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add device</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
