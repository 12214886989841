import React, { useMemo, useEffect, useState, useRef } from "react";
import "./sLoader.css";
import { useSelector } from "react-redux";
export const sLoader = (windowSize) => {
  return (
    <div
      className="outer"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: windowSize ?? undefined,
      }}
    >
      {/* <meta name="description" content="Helmet application" /> */}
      {/* <title>My Title</title> */}
      <div className="inner">
        <div className="loader" />
        <div className="s" />
      </div>
      <div className="footer">www.sensioair.com</div>
    </div>
  );
};

export const SmallLoader = ({}) => {
  return (
    <div className="outer">
      <div className="inner">
        <div className="loader" />
        <div className="s" />
      </div>
    </div>
  );
};

export const SLoader = ({}) => {
  // const windowHeight = useSelector(state => state.ui.windowHeight)
  // const [windowSize, setWindowSize] = useState(
  //     window.innerHeight,
  // );
  // useEffect(() => {
  //     const handleWindowResize = () => {
  //         setWindowSize(window.innerHeight);
  //     };
  //     window.addEventListener('resize', handleWindowResize);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowResize);
  //     };
  // });
  // useEffect(() => {
  //     setWindowSize(window.innerHeight)
  // }, []);
  return (
    // <div className="outer" style={{ height: windowHeight }}>
    //   <div className="inner">
    //     <div className="loader" />
    //     <div className="s" />
    //   </div>
    //   <div className="footer">www.sensioair.com</div>
    // </div>
    <div className="outer" style={{ height: "100vh" }}>
      <div className="inner">
        <div className="loader" />
        <div className="s" />
      </div>
      <div className="footer">www.sensioair.com</div>
    </div>
  );
};
