import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useWretchPut } from "../hooks/useWretchPut";
import { useWretchDelete } from "../hooks/useWretchDelete";
import { useDispatch } from "react-redux";

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const put = useWretchPut(dispatch);
  const Delete = useWretchDelete(dispatch);
  const location = useLocation();
  const { userId } = useParams();
  const { user } = location.state;
  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  // Determine if it is a mobile device
  const isMobile = useMediaQuery("(max-width: 950px)");

  const [menuOpen, setMenuOpen] = useState(false);

  // User information
  const userDetails = [
    { label: "Auth Provider", value: user.authProvider, editable: false },
    { label: "Email", value: user.email, editable: true },
    {
      label: "Email Verified",
      value: user.emailVerified.toString(),
      editable: true,
    },
    { label: "Phone Number", value: user.phoneNumber || "-", editable: true },
    {
      label: "Phone Verified",
      value: user.phoneVerified.toString(),
      editable: true,
    },
    { label: "User Type", value: user.userType, editable: true },
    {
      label: "User Devices",
      value: user.userDevices,
      editable: true,
    },
    {
      label: "ScreenSaver",
      value: user.screenSaver.toString(),
      editable: true,
    },
  ];

  const [editedUserDetails, setEditedUserDetails] = useState(userDetails);

  /////////////////////////////////////////////////////////////////////////////////////
  // Functionality that allows you to remove or add new devices to the user
  const [newDevice, setNewDevice] = useState("");

  const handleAddDevice = (newDevice) => {
    const updatedDevices = [...editedUserDetails];
    updatedDevices[6].value = [...updatedDevices[6].value, newDevice]; // Refer to index 6 for User Devices
    setEditedUserDetails(updatedDevices);
    setNewDevice(""); // Clear the input field after adding
  };

  const handleRemoveDevice = (deviceIndex) => {
    const updatedDevices = [...editedUserDetails];
    if (deviceIndex >= 0 && deviceIndex < updatedDevices[6].value.length) {
      updatedDevices[6].value.splice(deviceIndex, 1); // Remove the device from the User Devices list if the index is correct
      setEditedUserDetails(updatedDevices);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////
  // Saving user data and sending it to the server
  const handleSave = async () => {
    setLoading(true);
    const editedUserEmail = editedUserDetails.find(
      (item) => item.label === "Email"
    ).value;
    const editedUserEmailVerified = editedUserDetails.find(
      (item) => item.label === "Email Verified"
    ).value;
    const editedPhoneNumber = editedUserDetails.find(
      (item) => item.label === "Phone Number"
    ).value;
    const editedPhoneVerified = editedUserDetails.find(
      (item) => item.label === "Phone Verified"
    ).value;
    const editedUserDevices = editedUserDetails.find(
      (item) => item.label === "User Devices"
    ).value;
    const editedScreenSaver = editedUserDetails.find(
      (item) => item.label === "ScreenSaver"
    ).value;

    console.log("userID:", userId);

    const userData = {
      userId: userId,
      newEmail: editedUserEmail,
      newEmailVerified: editedUserEmailVerified,
      newPhoneNumber: editedPhoneNumber,
      newPhoneVerified: editedPhoneVerified,
      editedUserDevices: editedUserDevices,
      newScreenSaver: editedScreenSaver,
    };

    try {
      await put("/user/editUser", { userData });
      setLoading(false);
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating user data:", error);
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  // Function of removing a user from the database
  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      const response = await Delete(`/user/deleteUser/${userId}`);
      if (response.error == false) {
        setSuccessSnackbarOpen(true);
        setInterval(() => {
          navigate(`/admin`);
        }, 2000);
      }
    } catch (error) {
      // Handling errors during deletion
      console.error("Error deleting user:", error);
      setLoading(false);
    }
  };

  // Return to the AdminPanel page
  const handleBackToAdminPanel = () => {
    navigate(`/admin`);
  };

  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        overflowY: "auto",
        height: "111vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zoom: "90%",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          Successful operation!
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: isMobile ? "100%" : "70%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            onClick={() => setMenuOpen(!menuOpen)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!isMobile || menuOpen ? (
          <>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              User ID: #{userId}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{
                  mr: 1,
                  bgcolor: "#4caf50",
                  "&:hover": { bgcolor: "#388e3c" },
                }}
              >
                Save
              </Button>
              <Button
                onClick={handleDeleteUser}
                variant="contained"
                color="secondary"
                sx={{
                  mr: 1,
                  bgcolor: "#f44336",
                  "&:hover": { bgcolor: "#d32f2f" },
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleBackToAdminPanel}
                variant="contained"
                sx={{
                  bgcolor: "#2196f3",
                  "&:hover": { bgcolor: "#1976d2" },
                }}
              >
                Back
              </Button>
            </Box>
          </>
        ) : null}
      </Box>

      {/* Main container */}
      <Box
        sx={{
          width: isMobile ? "100%" : "70%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    borderTop: "1px solid  rgba(224, 224, 224, 1)",
                  }}
                >
                  Attribute
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    borderTop: "1px solid  rgba(224, 224, 224, 1)",
                  }}
                >
                  Value
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editedUserDetails.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.label}</TableCell>
                  <TableCell>
                    {item.editable ? (
                      item.label === "User Devices" ? (
                        <div>
                          {item.value.map((device, deviceIndex) => (
                            <div
                              key={deviceIndex}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography style={{ marginRight: "10px" }}>
                                {device}
                              </Typography>
                              <IconButton
                                color="secondary"
                                aria-label="delete device"
                                onClick={() => handleRemoveDevice(deviceIndex)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              variant="outlined"
                              value={newDevice}
                              onChange={(e) => setNewDevice(e.target.value)}
                              style={{ marginRight: "10px" }}
                            />
                            <IconButton
                              color="primary"
                              aria-label="add device"
                              onClick={() => {
                                handleAddDevice(newDevice);
                                setNewDevice("");
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </div>
                      ) : item.label === "Email Verified" ||
                        item.label === "Phone Verified" ||
                        item.label === "ScreenSaver" ? (
                        <>
                          <span>{item.value ? "On" : "Off"}</span>
                          <Switch
                            checked={
                              item.value === "true" || item.value === true
                            }
                            onChange={(e) => {
                              const updatedDetails = [...editedUserDetails];
                              updatedDetails[index].value = e.target.checked;
                              setEditedUserDetails(updatedDetails);
                            }}
                          />
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={item.value}
                          onChange={(e) => {
                            const updatedDetails = [...editedUserDetails];
                            updatedDetails[index].value = e.target.value;
                            setEditedUserDetails(updatedDetails);
                          }}
                        />
                      )
                    ) : (
                      item.value
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default UserDetailsPage;
