import { API_URL } from "../../consts";
import { useWretchGet } from "../../hooks/useWretchGet";
import {
  fetchQrFailure,
  fetchQrStart,
  fetchQrSuccess,
} from "../slices/qrSlice";

export const fetchQr = () => async (dispatch) => {
  dispatch(fetchQrStart());
  const get = useWretchGet(dispatch);
  try {
    const response = await get(`/user/getQr`);
    if (Array.isArray(response) && response.length) {
      dispatch(fetchQrSuccess(response));
    } else if (response?.error === false) {
      dispatch(fetchQrSuccess(`${API_URL}/public/qr/${response?.message}`));
    }
  } catch (error) {
    dispatch(fetchQrFailure(error.toString()));
  }
};
