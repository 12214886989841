import React from "react";

const ImageModalPopap = ({ setModalOpen, activeImage }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={() => setModalOpen(false)}
    >
      <img
        className="modal-image"
        onClick={(e) => e.stopPropagation()}
        src={activeImage}
        alt="Preview"
      />
      <button className="modal-close-btn" onClick={() => setModalOpen(false)}>
        X
      </button>
    </div>
  );
};

export default ImageModalPopap;
