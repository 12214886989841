import ScaleBox from "./components/ScaleBox"
export const learnMoreModalBody = () => {
  return (
    <div>
      <ScaleBox bell={false} text={'Levels'} />
      <span style={{ textAlign: 'justify' }}>
        <p>Glossary of Pollutants</p>
        <p>Pollen is tiny grains released by plants, particularly trees, grasses, and weeds, that can cause allergic reactions in some people. Pollen can enter buildings through open windows and doors, and can also be carried in on clothing and hair.</p>
        <p>Mold is a type of fungus that can grow indoors and outdoors in damp or humid environments. Mold can cause allergic reactions and can also damage building materials.</p>
        <p>Dust mites are tiny, eight-legged creatures that live in dust and feed on skin cells. They can cause allergic reactions in some people, and their feces and decomposing bodies can also be allergens.</p>
        <p>Pet dander refers to the tiny flakes of skin that are shed by animals, including dogs and cats. Pet dander can cause allergic reactions in some people.</p>
        <p>VOCs (volatile organic compounds) are chemicals that can be released into the air from a variety of sources, including paint, cleaning products, and building materials. Some VOCs can have negative health effects such as headaches, nausea, and respiratory problems.</p>
        <p>CO2 (carbon dioxide) is a naturally occurring gas that is exhaled by humans and animals. Elevated levels of CO2 can indicate poor ventilation and can lead to symptoms such as headaches, fatigue, and decreased cognitive function.</p>
      </span>
      <br />
      <br />
      <ScaleBox bell={true} text={' Temperature and Humidity Levels*'} />
      <span style={{ textAlign: 'justify' }}>
        <p>Humidity refers to the amount of water vapor in the air. High humidity can cause discomfort and can also promote the growth of mold and dust mites. </p>
        <p>Temperature refers to the degree of heat or cold in a space. Temperature can affect comfort levels and can also affect the concentration of pollutants such as VOCs. </p>
        <p>*Indices for temperature and humidity are different from others, they follow a bell shaped curve for comfort.
        </p>
      </span>
      <br />
      <br />
      <ScaleBox bell={false} text={'Levels'} />
      <span style={{ textAlign: 'justify' }}>
        <p>Glossary of Insights</p>
        <p>Stuffiness refers to the feeling of discomfort caused by stagnant, humid air that can lead to a buildup of pollutants and allergens.</p>
        <p>Transmission Index refers to the movement of air through a space, including factors such as ventilation and airflow. This can have an impact on indoor air quality and the dispersal of pollutants and allergens.</p>
        <p>Density of a room refers to the number of people and objects within a space, which can affect the concentration of pollutants and allergens as well as the overall comfort level.</p>
        <p>Indoor Pollution refers to the presence of harmful substances within a building or enclosed space, such as chemicals, allergens, and particulate matter. These pollutants can have negative effects on health and comfort.</p>
        <p>Indoor Comfort refers to the overall well-being and satisfaction of the people in a space, taking into account factors such as temperature, humidity, lighting, and air quality. Improving indoor comfort can involve reducing pollutants and increasing ventilation, among other measures.</p>
      </span>
    </div>
  )
}