import { useWretchPost } from "../../hooks/useWretchPost";
import {
  markConcernAsDoneFailure,
  markConcernAsDoneStart,
  markConcernAsDoneSuccess,
} from "../slices/concernDoneSlice";
import { fetchNewConcerns } from "./newConcernsThunk";

export const markConcernAsDone =
  (id, userToken, callback) => async (dispatch) => {
    dispatch(markConcernAsDoneStart());
    const post = useWretchPost(dispatch);
    try {
      const response = await post(`/concerns/done/${id}`);

      dispatch(markConcernAsDoneSuccess());

      dispatch(fetchNewConcerns());
      return response;
    } catch (error) {
      dispatch(markConcernAsDoneFailure(error.toString()));
    }
  };
