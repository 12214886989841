import { useWretchGet } from "../../hooks/useWretchGet";
import {
  fetchNewConcernsFailure,
  fetchNewConcernsStart,
  fetchNewConcernsSuccess,
} from "../slices/newConcernsSlice";

export const fetchNewConcerns = () => async (dispatch) => {
  dispatch(fetchNewConcernsStart());
  const get = useWretchGet(dispatch);
  try {
    const response = await get(`/concerns`);

    dispatch(fetchNewConcernsSuccess(response));
  } catch (error) {
    dispatch(fetchNewConcernsFailure(error.toString()));
  }
};
