import { createSlice } from "@reduxjs/toolkit";

export const indoorDataSlice = createSlice({
  name: "indoorData",
  initialState: {
    value: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchIndoorDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchIndoorDataSuccess: (state, action) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchIndoorDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchIndoorDataStart,
  fetchIndoorDataSuccess,
  fetchIndoorDataFailure,
} = indoorDataSlice.actions;

export const selectIndoorData = (state) => state.indoorData.value;

export default indoorDataSlice.reducer;
