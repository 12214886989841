import React from "react";
import styles from "./BigIndexBox.module.css";
import iconPollutants from "../assets/iconPollutants.svg";
import iconPathogens from "../assets/iconPathogens.svg";

const getDesc = (value) => {
  if (Math.round(value) > 7) return "High";
  if (Math.round(value) > 4) return "Moderate";
  if (Math.round(value) > 0) return "Low";
  else return "No data";
};
export const getBackgroundColor = (value) => {
  if (Math.round(value) > 7) return { index: "#ED2B2A", desc: "#F15A59" };
  if (Math.round(value) > 4) return { index: "#F48A4F", desc: "#FF9F00" };
  if (Math.round(value) > 0) return { index: "#3BA510", desc: "#92C746" };
  else return { index: "#d3d3d3", desc: "#d3d3d3" };
};

export function BigIndexBox({ value, type, testViewHeight, testViewWidth }) {
  //   const [phase, setPhase] = React.useState(1);

  return (
    <div className={`${"myshadow"} ${styles.container}`}>
      <div
        className={`${styles.top}`}
        style={{
          fontSize: testViewHeight && testViewWidth == true ? 38 : undefined,
        }}
      >
        <img src={type === "Pollutants" ? iconPollutants : iconPathogens} />
        <div>{type}</div>
      </div>
      <div className={`${""} ${styles.bottom}`}>
        <div
          className={`${styles.colorBox}`}
          style={{
            height: testViewHeight && testViewWidth == true ? 80 : undefined,
          }}
        >
          <div
            className={`${styles.index}`}
            style={{
              background: getBackgroundColor(value)?.index,
              width: testViewHeight && testViewWidth == true ? 100 : "",
            }}
          >
            {Math.round(value) > 0 ? Math.round(value) : null}
          </div>
          <div
            className={`${styles.desc}`}
            style={{
              background: getBackgroundColor(value)?.desc,
              fontSize:
                testViewHeight && testViewWidth == true ? 40 : undefined,
            }}
          >
            {getDesc(value)}
          </div>
        </div>
      </div>
    </div>
  );
}
