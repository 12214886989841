import { API_URL } from "../consts";
import wretch from "wretch";
import { setError } from "../store/slices/errorSlice";

export function useWretchGet(dispatch) {
  const createWretchInstance = async (url = "", options = {}) => {
    // We can pass an additional url as a parameter, and it will supplement the base API_URL.
    const fullUrl = url ? `${API_URL}${url}` : API_URL;

    const token = localStorage.getItem("token");

    let response
    try {
       response = await wretch()
        .url(fullUrl)
        // .auth(`Bearer ${token}`) // Додавання JWT токену до заголовка Authorization
        .options({
          ...options,
          credentials: "include", // Add an option for transferring credentials (cookies)
        })
        .get()
        .notFound((error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .unauthorized((error) => {
          // localStorage.removeItem("token");
          let parsedError;
          try {
            parsedError = JSON.parse(error.message);
          } catch (e) {
          }

          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          // window.location.href = "/";
        })
        .error(418, (error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .res();

      // Check if the response is okay, otherwise throw an error
      if (!response?.ok) {
        throw new Error("An error occurred while fetching the data");
      }

      return await response.json(); // Parsing and returning the JSON response body.
    } catch (error) {
      try {
        if (response?.status != 401) {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
        }
      } catch (parsingError) {

      }
    }
  };

  return createWretchInstance;
}
