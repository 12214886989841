  const ScaleBox = ({ bell, text }) => {
    let arr = [];
    for (let i = 1; i < 11; i++) {
      arr.push(i)
    }
    return (
      <>
      <span style={{fontSize:"120%"}}>{text}</span>
        <div className={`ScaleGradient ${bell && 'ScaleGradientBell'}`}>
          {arr.map((item, idx) => { return (<span key={idx}>{item}</span>) })}
        </div>
        <span style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 5px', marginBottom: 20 }}>
          <span>
            Low
          </span>
          <span>
            Medum
          </span>
          <span>
            High
          </span>
        </span>
      </>
    )
  }

  export default ScaleBox



  