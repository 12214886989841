// userThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUsers } from "../slices/usersSlice";
import { useWretchGet } from "../../hooks/useWretchGet";

// Create a thunk to get a list of users
export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (_, { dispatch }) => {
    try {
      const get = useWretchGet(dispatch);
      const response = await get("/user/getAllUsers");

      if (response.status === "success") {
        // Update the Redux page with the received data
        dispatch(setUsers(response.users));
        return true;
      }
    } catch (error) {
      throw error;
    }
  }
);
