import React, { useState } from "react";
import { Paper, Typography, TextField, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import "./PasswordResetForm.css";
import { useWretchPost } from "../hooks/useWretchPost";
import { useDispatch } from "react-redux";

const PasswordResetForm = () => {
  const dispatch = useDispatch();
  const post = useWretchPost(dispatch);
  const { id } = useParams(); // Get with URL
  const { token } = useParams(); // Get with URL

  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // If the user entered the new password correctly twice = true
      if (newPassword === repeatPassword) {
        // Send the new password to the server along with the token
        const encodedId = encodeURIComponent(id);
        const encodedToken = encodeURIComponent(token);
        const response = await post(
          `/auth/reset-password/${encodedId}/${encodedToken}`,
          { oldPassword: oldPassword, newPassword: newPassword }
        );
        // Notify the user of a successful password reset
        setError(response.error);
        setMessage(response.message);
      } else {
        setError(true);
        setMessage("Passwords don't match");
        return;
      }
    } catch (error) {
      setMessage(
        "An error occurred while changing your password. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="passwordReset__main-container ">
      <Paper elevation={3} style={{ padding: 20, maxWidth: 300 }}>
        <Typography variant="h6" gutterBottom>
          Resetting the password
        </Typography>
        {message && (
          <div
            style={{
              fontSize: 9,
              width: 240,
              height: 25,
              color: error ? "red" : "green",
            }}
          >
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: 10 }}>
            <TextField
              fullWidth
              id="newPassword"
              label="New password"
              variant="outlined"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              margin="dense"
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <TextField
              fullWidth
              id="repeatPassword"
              label="Repeat password"
              variant="outlined"
              type="password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
              margin="dense"
            />
          </div>
          <Button
            style={{ width: 240 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Change password
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default PasswordResetForm;
