import { createSlice } from "@reduxjs/toolkit";

export const cleaningRequestSlice = createSlice({
  name: "cleaningRequest",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    requestCleaningStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestCleaningSuccess: (state) => {
      state.loading = false;
    },
    requestCleaningFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  requestCleaningStart,
  requestCleaningSuccess,
  requestCleaningFailure,
} = cleaningRequestSlice.actions;

export default cleaningRequestSlice.reducer;
