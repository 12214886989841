import React, { useEffect, useState, useCallback, useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { API_URL, PUBLIC_URL } from "./consts";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SLoader, sLoader, SmallLoader } from "./components/sLoader/sLoader";
import { AirQuality } from "./assets/AirQuality.js";
import { BigIndexBox } from "./components/BigIndexBox";
import { BarIndicator } from "./components/BarIndicator";
import { updateAuth } from "./store/slices/authSlice";
import { indoorDataSlice } from "./store/slices/indoorDataSlice.js";
import { learnMoreModalBody } from "./modals";
import triangle from "./assets/Triangle.svg";
import "swiper/css";
import "./Main.css";
// import "./Main copy 2.css";
import styles from "./data.module.css";
import "./data.module.css";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import ReportConcern from "./components/ReportConcern";
import moment from "moment";
import * as dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import { useSelector } from "react-redux";
import { Radar, Line } from "react-chartjs-2";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-any-slider-dots/dist/dots.css";
import { ReactAnySliderDots as Dots } from "react-any-slider-dots";
import { backgroundPalette } from "./BigButton";
import { useSwipeable } from "react-swipeable";
import { useIdleTimer } from "react-idle-timer";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { CSSTransition } from "react-transition-group";
import { useLongPress } from "use-long-press";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  getShortIndexName,
  getIndexColor,
  getIndex,
  getConditions,
  getChartDataColor,
  getAverage,
} from "./utils/indexes";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";

import {
  swipeableConfig,
  chartBackgroundPalette,
  backgroundRadarOptions,
  radarOptions,
  optionsLine,
  backgroundRadarOptionsTest,
} from "./configs";
import { Alert, Backdrop, Snackbar, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectDevices } from "./store/slices/devicesSlice";
import { fetchDevices } from "./store/thunk/devicesThunk";
import { selectIndoorData } from "./store/slices/indoorDataSlice";
import { fetchIndoorData } from "./store/thunk/indoorDataThunk";
import { selectQr } from "./store/slices/qrSlice";
import { fetchQr } from "./store/thunk/qrThunk";
import { selectIotData } from "./store/slices/iotDataSlice";
import { fetchIotData } from "./store/thunk/iotDataThunk";
import { fetchConcerns } from "./store/thunk/concernsThunk";
import { selectConcerns } from "./store/slices/concernsSlice";
import { fetchNewConcerns } from "./store/thunk/newConcernsThunk";
import { selectNewConcerns } from "./store/slices/newConcernsSlice";
import { markConcernAsDone } from "./store/thunk/concernDoneThunk";
import ImageModalPopap from "./components/ImageModalPopap";
import { useWretchPost } from "./hooks/useWretchPost.js";
import AddDeviceModal from "./components/AddDeviceModal.js";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "90%",
    height: "85vh",
    maxWidth: "unset",
    borderRadius: 20,
  },
});

// function Main({token="24c098b0b05920f685f07cc0a467b2e61af36e8c"}) {
function Data({ token = "fe1e3426a3421f4abe0405f2cca3025048b8870a" }) {
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const post = useWretchPost(dispatch);
  const chartContainerRef = useRef();
  const requestCleaningContainerRef = useRef();
  const swiperContainerRef = useRef();
  const [swiper, setSwiper] = useState(null);
  const analyseContainerRef = useRef();
  const [chartHeight, setChartHeight] = useState(undefined);
  const [chartWidth, setChartWidth] = useState(undefined);
  const bottomContainerRef = useRef();
  const userDevices = useSelector((state) => state.auth.userDevices);
  const userType = useSelector((state) => state.auth.userType);
  const userData = useSelector((state) => state.auth.user);
  const windowHeight = useSelector((state) => state.ui.windowHeight);
  const nodeRef = useRef();
  const [inProp, setInProp] = useState(false);
  const [uploaderResponse, setUploaderResponse] = useState(null);
  const indoorDataLoading = useSelector((state) => state.indoorData.loading);
  const devicesLoading = useSelector((state) => state.devices.loading);
  const [noData, setNoData] = useState(true);
  const [detailedView, setDetailedView] = useState(1);
  const [viewType, setViewType] = useState(0);
  const indoorData = useSelector(selectIndoorData);
  const [chartContainerHeight, setChartContainerHeight] = useState(undefined);
  const [chartContainerWidth, setChartContainerWidth] = useState(undefined);
  const [swiperContainerSize, setSwiperContainerSize] = useState(undefined);
  const [analyseContainerSize, setAnalyseContainerSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [bottomContainerHeight, setBottomContainerHeight] = useState(undefined);
  const [requestCleaningContainerHeight, setRequestCleaningContainerHeight] =
    useState(undefined);
  const [chartData1, setChartData1] = useState([]);
  const [chartLabels1, setChartLabels1] = useState([]);
  const [learnMore, setLearnMore] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [mergedDataTypes, setmergedDataTypes] = useState([]);
  const [mergedDataTypesLabels, setmergedDataTypesLabels] = useState([]);
  const [chartHData1, setChartHData1] = useState([]);
  const [chartHLabels1, setChartHLabels1] = useState([]);
  const [chartHData2, setChartHData2] = useState([]);
  const [initialWindowHeight, setInitialWindowHeight] = useState(
    window.innerHeight
  );
  const [radar, setRadar] = useState({});
  const [newRadarData, setNewRadarData] = useState({});
  const [backgroundRadar, setBackgroundRadar] = useState({});
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const iotData = useSelector(selectIotData);

  const [activeDeviceIotConnected, setActiveDeviceIotConnected] =
    useState(false);
  const [activeDeviceIotData, setActiveDeviceIotData] = useState([]);
  const concerns = useSelector(selectConcerns);
  const newConcerns = useSelector(selectNewConcerns);
  const [concerns2, setConcerns2] = useState([]);
  const [cleaningRequestInProgress, setCleaningRequestInProgress] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartLoadingCounter, setChartLoadingCounter] = useState(0);
  const [datePickerVal, setDatePickerVal] = useState({
    ["1"]: new Date(Date.UTC(2023, 2, 1, 0, 0, 0)),
    ["2"]: new Date(Date.UTC(2023, 3, 1, 0, 0, 0)),
  });

  const devices = useSelector(selectDevices);

  const [loadingProcess, setLoadingProcess] = useState(false);
  const [isNonDeviceSlide, setIsNonDeviceSlide] = useState(undefined);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [addDeviceCode, setAddDeviceCode] = useState(undefined);
  const [temperatureScale, setTemperatureScale] = useState(undefined);

  const [activeDeviceIndex, setActiveDeviceIndex] = useState(undefined);
  useEffect(() => {
    setActiveDeviceIndex(0);
  }, [iotData]);
  const activeDevice = devices?.[activeDeviceIndex] ?? null;
  const activeDeviceIndoorData =
    indoorData?.filter(
      (d) => d?.device_serial === activeDevice?.serial_number
    )?.[0] ?? null;

  const cleaningRequested = newConcerns?.find(
    (c) =>
      c?.deviceSerial === activeDevice?.serial_number &&
      c?.type === "cleaning" &&
      !c?.done
  );

  const onIdle = () => {
    setIdle(true);
  };

  const onActive = () => {
    setIdle(false);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    // onAction,
    timeout: 60_000,
    throttle: 500,
  });

  ///////////////////////////////////////////////////////////////////////////////////////
  // Logout function
  const logout = async () => {
    try {
      localStorage.removeItem("token");
      if (localStorage.getItem("token") === null) {
        dispatch(updateAuth(false));
        navigate(`/`);
      } else {
        console.error("Error while deleting data from Session Storage.");
      }
    } catch (error) {
      console.error("Error while deleting data from Session Storage.", error);
    }
  };

  const markAsHiddenLongPress = useLongPress((e, item) => {
    if (item?.context?._id) markAsHidden(item?.context?._id);
  });

  const reportConcernLongPress = useLongPress(() => {
    setModalType("concern");
    setShowModal(true);
  });

  const showConcernsLongPress = useLongPress(() => {
    setModalType("showConcerns");
    setShowModal(true);
  });

  useEffect(() => {
    const handleWindowResize = () => {
      //   setwindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    let portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", function (e) {
      if (e.matches) {
      } else {
      }
    });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    var xStart,
      yStart = 0;
    const handleWindowResize = () => {
      //  setwindowHeight(window.innerHeight);
    };

    const touchStartBlock = (e) => {
      xStart = e.touches[0].screenX;
      yStart = e.touches[0].screenY;
    };
    const touchMoveBlock = (e) => {
      var xMovement = Math.abs(e.touches[0].screenX - xStart);
      var yMovement = Math.abs(e.touches[0].screenY - yStart);
      if (!showModal /*|| (yMovement * 3 > xMovement*/) {
        //  e.preventDefault();
      }
    };

    document.addEventListener("touchstart", touchStartBlock);
    document.addEventListener("touchmove", touchMoveBlock, { passive: false });

    return () => {
      window.removeEventListener("touchmove", touchStartBlock);
      window.removeEventListener("touchstart", touchMoveBlock);
    };
  });

  ////// T M P
  useEffect(() => {
    window.scrollTo(0, 0);
    screen?.orientation?.addEventListener("change", (event) => {
      const type = event.target.type;
      setViewType(0);
    });
  }, []);

  useEffect(() => {
    const adjustHeight = () => {
      if (window.innerHeight < initialWindowHeight) {
        window.scrollTo(0, 0);
      }
    };

    document.addEventListener("focusin", adjustHeight);
    document.addEventListener("focusout", adjustHeight);
  }, []);

  // const isLoading1 = useSelector((state) => state.indoorData.loading);
  // console.log(isLoading1);

  // T M P

  // const addDevice = async (addDeviceCode) => {
  //   console.log("add d called");
  //   try {
  //     console.log("const res");

  //     const res = await post(`/device/add`, {
  //       deviceSerial: addDeviceCode,
  //     });
  //     setAddDeviceCode(undefined);
  //     console.log("console log res");

  //     console.log("res", res);
  //     return res;
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // useEffect(() => {
  //   if (!addDeviceCode) return;
  //   addDevice(addDeviceCode);
  // }, [addDeviceCode]);

  const chartRef = useCallback(
    (node) => {
      if (node !== null) {
        setChartHeight(node?.chartArea?.height);
        setChartWidth(node?.chartArea?.width);
      }
    },
    [windowHeight, chartContainerHeight]
  );

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // Function responsible for switching the view

  let viewTypesLength = 2;

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (testViewWidth || window.innerWidth > 800) return;

      if (eventData?.initial?.[1] < swiperContainerSize) return;
      if (eventData?.dir) {
        if (eventData?.dir === "Right" && viewType !== viewTypesLength - 1) {
          setViewType((old) => old + 1);
        } else if (eventData?.dir === "Left" && viewType !== 0) {
          setViewType((old) => old - 1);
        }
      }
    },
    ...swipeableConfig,
  });

  //////////////////////////////////////////////////////////////////////////////////////////////// END

  //////////////////////////////////////////////////////////////////////////////////////////////// START
  // Function that loads the screenSaver state
  const [screenSaverState, setScreenSaverState] = useState(false);

  useEffect(() => {
    const storedUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (storedUserData) {
      setScreenSaverState(storedUserData.screenSaver);
    }
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////// END

  const bottomRefPassthrough = (el) => {
    // call useSwipeables ref prop with el
    // handlers.ref(el);
    // set the el to a ref you can access yourself
    bottomContainerRef.current = el;
  };

  const [barWidth, setBarWidth] = useState(10);
  const [swiperState, setSwiperState] = useState([]);

  const [idle, setIdle] = useState(false);
  const [grad, setGrad] = useState(null);
  const [conditions, setConditions] = useState("transparent");
  const [activeDeviceData, setActiveDeviceData] = useState({});
  const [activeDeviceHistoryData1, setactiveDeviceHistoryData1] = useState({});
  const [activeDeviceHistoryData2, setactiveDeviceHistoryData2] = useState({});

  const [userToken, setUserToken] = useState(token);
  // const [userToken, setUserToken] = useState("fe1e3426a3421f4abe0405f2cca3025048b8870a");

  const [tabSwitchValue, setTabSwitchValue] = useState(0);

  const { qrCodes, qrCodeLink } = useSelector(selectQr);

  useEffect(() => {
    const getIndoorDataInterval = setInterval(() => {
      dispatch(fetchIndoorData(dispatch));
    }, 1000 * 45);

    dispatch(fetchIndoorData(dispatch));

    return () => clearInterval(getIndoorDataInterval);
  }, []);

  //////////////////////////////////////////////////////////////////////////
  // GET QR code
  useEffect(() => {
    dispatch(fetchQr());
  }, [dispatch]);

  useEffect(() => {
    setChartContainerHeight(
      chartContainerRef?.current?.clientHeight ?? undefined
    );
    chartContainerRef?.current?.clientWidth > 5 &&
      setChartContainerWidth(chartContainerRef?.current?.clientWidth);
    setSwiperContainerSize(
      swiperContainerRef?.current?.clientHeight ?? undefined
    );
    setBottomContainerHeight(
      bottomContainerRef?.current?.clientHeight ?? undefined
    );

    setAnalyseContainerSize((prevState) => ({
      ...prevState,
      width: analyseContainerRef?.current?.clientWidth ?? undefined,
    }));

    setAnalyseContainerSize((prevState) => ({
      ...prevState,
      height: analyseContainerRef?.current?.clientHeight ?? undefined,
    }));
  }, [activeDeviceIndex, activeDeviceData, chartHData1, windowHeight]);

  useEffect(() => {
    setmergedDataTypes([...new Set(mergedDataTypes)]);
    setmergedDataTypesLabels([...new Set(mergedDataTypesLabels)]);
    // setChartLoading(false)
  }, [activeDeviceHistoryData1]);

  useEffect(() => {
    if (!loading) setDetailedView(0);
  }, [loading]);

  useEffect(() => {
    if (
      // iotData.length &&
      // Array.isArray(devices) &&
      // activeDeviceHistoryData1?.sensors &&
      chartHeight &&
      !indoorDataLoading &&
      !devicesLoading &&
      requestCleaningContainerHeight
      // &&
      // (qrCodeLink || qrCodes.length)
    ) {
      setLoading(false);
    }
  }, [
    iotData,
    devices,
    activeDeviceHistoryData1,
    chartHeight,
    indoorData,
    requestCleaningContainerHeight,
    qrCodeLink,
    qrCodes,
  ]);

  const onUploaderResponse = (res) => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (res) {
      getNewConcerns();
      setShowModal(false);
      setModalType(null);
    } else {
      alert("something goes wrong");
      setShowModal(false);
      setModalType(null);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    setConditions(getConditions(chartHData1));

    // console.log(chartHData1)

    const dataExist1 = chartHData1.filter((e) => e > 0);
    const dataExist2 = chartHData2.filter((e) => e > 0);
    if (dataExist1.length || dataExist2.length) {
      setNoData(false);
    } else setNoData(true);
  }, [chartHData1, chartHData2]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 10);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      //   console.log(barWidth);
      //  if (barWidth !== 99) setBarWidth((barWidth) => barWidth + 1)
      //  else clearInterval(interval)
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const getSensorHistoricData = (chartNo, sensor) => {
    if (!chartNo || !sensor) return null;
    if (chartNo === 1 && !activeDeviceHistoryData1?.sensors?.length)
      return null;
    if (chartNo === 2 && !activeDeviceHistoryData2?.sensors?.length)
      return null;
    let source;
    if (chartNo === 1) source = activeDeviceHistoryData1;
    if (chartNo === 2) source = activeDeviceHistoryData2;
    if (!source || !source?.sensors) return null;

    if (sensor === "mold")
      return source?.sensors?.filter((s) => s.sensor_category_id === 21);
    if (sensor === "pollen")
      return source?.sensors?.filter((s) => s.sensor_category_id === 20);
    else
      return source?.sensors?.filter(
        (s) => s.code_name.toLowerCase() === sensor
      );
  };

  const getDetailedChartTypeData = (chartNo) => {
    const getIndexArrayForCombinedData = (sensorsArr) => {
      try {
        const sumArrays = (...arrays) => {
          const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
          const result = Array.from({ length: n });
          return result.map((_, i) =>
            arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
          );
        };
        const elements = [];

        sensorsArr.forEach((sensor) => {
          elements.push(
            getAverage(getSensorHistoricData(1, sensor), sensor, "indexArray")
          );
        });

        return sumArrays(...elements).map((val) =>
          (val / sensorsArr.length).toFixed()
        );
      } catch (e) {
        return null;
      }
    };

    const getDataArray = (params) => {
      if (params.length > 1) {
        let results = Array();
        params.forEach((p) => {
          results.push(getAverage(getSensorHistoricData(chartNo, p), p));
        });
        return results;
      } else if (params.length) {
        return getAverage(getSensorHistoricData(chartNo, params[0]), params[0]);
      }
    };

    // console.log(
    //   getIndexArrayForCombinedData([
    //     'temperature',
    //     'humidity',
    //     'voc',
    //     'eco2',
    //   ])
    // )

    //debug
    //   console.log('__original', ([ //stuffiness
    //   getAverage(getSensorHistoricData(chartNo, 'temperature'), 'temperature'),
    //   getAverage(getSensorHistoricData(chartNo, 'humidity'), 'humidity'),
    //   getAverage(getSensorHistoricData(chartNo, 'voc'), 'voc'),
    //   getAverage(getSensorHistoricData(chartNo, 'eco2'), 'eco2'),
    // ].reduce((a, b) => parseInt(a) + parseInt(b), 0) / 4).toFixed(),)

    //   console.log('__new     ', (getDataArray(['temperature', 'humidity', 'voc', 'eco2']).reduce((a, b) => parseInt(a) + parseInt(b), 0) / 4).toFixed(),)

    return [
      //stuffiness

      // getAverage(getSensorHistoricData(chartNo, 'temperature'), 'temperature'),
      // getAverage(getSensorHistoricData(chartNo, 'humidity'), 'humidity'),
      // getAverage(getSensorHistoricData(chartNo, 'voc'), 'voc'),
      // getAverage(getSensorHistoricData(chartNo, 'eco2'), 'eco2'),

      (
        getDataArray(["temperature", "humidity", "voc", "eco2"]).reduce(
          (a, b) => parseInt(a) + parseInt(b),
          0
        ) / 4
      ).toFixed(),

      //density

      // getAverage(getSensorHistoricData(chartNo, 'voc'), 'voc'),
      // getAverage(getSensorHistoricData(chartNo, 'eco2'), 'eco2'),

      (
        getDataArray(["voc", "eco2"]).reduce(
          (a, b) => parseInt(a) + parseInt(b),
          0
        ) / 2
      ).toFixed(),

      //temp
      getAverage(getSensorHistoricData(chartNo, "temperature"), "temperature"),

      (
        [
          //pollution
          getAverage(getSensorHistoricData(chartNo, "voc"), "voc"),
          getAverage(getSensorHistoricData(chartNo, "eco2"), "eco2"),
          Math.max(
            ...getDataArray(["mold", "dander", "mites", "pollen"])

            // getAverage(getSensorHistoricData(chartNo, 'mold'), 'mold'),
            // getAverage(getSensorHistoricData(chartNo, 'dander'), 'dander'),
            // getAverage(getSensorHistoricData(chartNo, 'mites'), 'mites'),
            // getAverage(getSensorHistoricData(chartNo, 'pollen'), 'pollen'),
          ),
        ].reduce((a, b) => parseInt(a) + parseInt(b), 0) / 3
      ).toFixed(),

      (
        [
          //transmission
          getAverage(
            getSensorHistoricData(chartNo, "temperature"),
            "temperature"
          ),
          getAverage(getSensorHistoricData(chartNo, "humidity"), "humidity"),
          getAverage(getSensorHistoricData(chartNo, "voc"), "voc"),
          getAverage(getSensorHistoricData(chartNo, "eco2"), "eco2"),
          Math.max(
            ...getDataArray(["mold", "dander", "mites", "pollen"])
            // getAverage(getSensorHistoricData(chartNo, 'mold'), 'mold'),
            // getAverage(getSensorHistoricData(chartNo, 'dander'), 'dander'),
            // getAverage(getSensorHistoricData(chartNo, 'mites'), 'mites'),
            // getAverage(getSensorHistoricData(chartNo, 'pollen'), 'pollen'),
          ),
        ].reduce((a, b) => parseInt(a) + parseInt(b), 0) / 5
      ).toFixed(),

      //humidity
      getAverage(getSensorHistoricData(chartNo, "humidity"), "humidity"),

      getAverage(getSensorHistoricData(chartNo, "eco2"), "eco2"),

      (
        [
          //comfort
          getAverage(
            getSensorHistoricData(chartNo, "temperature"),
            "temperature"
          ),
          getAverage(getSensorHistoricData(chartNo, "humidity"), "humidity"),

          (
            [
              //pollution
              getAverage(getSensorHistoricData(chartNo, "voc"), "voc"),
              getAverage(getSensorHistoricData(chartNo, "eco2"), "eco2"),
              Math.max(
                ...getDataArray(["mold", "dander", "mites", "pollen"])
                // getAverage(getSensorHistoricData(chartNo, 'mold'), 'mold'),
                // getAverage(getSensorHistoricData(chartNo, 'dander'), 'dander'),
                // getAverage(getSensorHistoricData(chartNo, 'mites'), 'mites'),
                // getAverage(getSensorHistoricData(chartNo, 'pollen'), 'pollen'),
              ),
            ].reduce((a, b) => parseInt(a) + parseInt(b), 0) / 3
          ).toFixed(),
        ].reduce((a, b) => parseInt(a) + parseInt(b), 0) / 3
      ).toFixed(),
    ];
  };

  useEffect(() => {
    // console.log(activeDeviceHistoryData1)
    if (activeDeviceHistoryData1?.sensors?.length) {
      if (activeDeviceHistoryData1?.sensors?.length > 0) {
        // ## TO DEL ## //
        // let danderCopy = JSON.parse(JSON.stringify(dander));
        // if (danderCopy[0]?.data?.value) {
        //   let danderCopyVals = danderCopy[0]?.data?.value.filter(val => val !== 0.0 && val !== null);
        //   // console.log(danderCopyVals??[])
        //   setLineData(danderCopyVals ?? [])
        // }
        // ## TO DEL ## //

        setmergedDataTypesLabels([
          //    'mold', 'dander', 'humid', 'mites', 'pollen', 'temp.', 'co2', 'voc', 'stuffiness', 'density', 'humid', 'pollution', 'transmission', 'temp.', 'co2', 'comfort',
          "mold",
          "dander",
          "humid",
          "mites",
          "pollen",
          "temp.",
          "co2",
          "voc",
          "stuffiness",
          "density",
          "humid",
          "pollution",
          "transmission",
          "temp.",
          "co2",
          "comfort",
        ]);

        var tmp = [
          getAverage(getSensorHistoricData(1, "mold"), "mold"),
          getAverage(getSensorHistoricData(1, "dander"), "dander"),
          getAverage(getSensorHistoricData(1, "temperature"), "temperature"),
          getAverage(getSensorHistoricData(1, "mites"), "mites"),
          getAverage(getSensorHistoricData(1, "pollen"), "pollen"),
          getAverage(getSensorHistoricData(1, "humidity"), "humidity"),
          getAverage(getSensorHistoricData(1, "eco2"), "eco2"),
          getAverage(getSensorHistoricData(1, "voc"), "voc"),
        ];

        if (nowMode) {
          const x = indoorData?.filter(
            (d) => d?.device_serial === activeDevice?.serial_number
          );
          tmp = x[0]?.indexes;
        }

        setmergedDataTypes(tmp?.concat(getDetailedChartTypeData(1)));

        if (detailedView) {
          setChartHLabels1([
            // 'mold', 'dander', 'humid', 'mites', 'pollen', 'temp.', 'co2', 'voc',
            "mold",
            "dander",
            "temp.",
            "mites",
            "pollen",
            "humid",
            "co2",
            "voc",
          ]);

          setChartHData1([
            getAverage(getSensorHistoricData(1, "mold"), "mold"),
            getAverage(getSensorHistoricData(1, "dander"), "dander"),
            getAverage(getSensorHistoricData(1, "temperature"), "temperature"),
            getAverage(getSensorHistoricData(1, "mites"), "mites"),
            getAverage(getSensorHistoricData(1, "pollen"), "pollen"),
            getAverage(getSensorHistoricData(1, "humidity"), "humidity"),
            getAverage(getSensorHistoricData(1, "eco2"), "eco2"),
            getAverage(getSensorHistoricData(1, "voc"), "voc"),
          ]);
        } else {
          setChartHLabels1([
            //  'stuffiness', 'density', 'humid', 'pollution', 'transmission', 'temp.', 'co2', 'comfort',
            "stuffiness",
            "density",
            "temp.",
            "pollution",
            "transmission",
            "humid",
            "co2",
            "comfort",
          ]);

          setChartHData1(getDetailedChartTypeData(1));
        }
      }
    }
  }, [activeDeviceHistoryData1, detailedView]);

  useEffect(() => {
    if (activeDeviceHistoryData2?.sensors?.length) {
      if (activeDeviceHistoryData2?.sensors?.length > 0) {
        if (detailedView) {
          //second dot
          setChartHLabels2([
            "mold",
            "dander",
            "temp.",
            "mites",
            "pollen",
            "humid",
            "co2",
            "voc",
          ]);
          // const anyReal = (getAverage(mold, 'mold') || getAverage(dander, 'dander') || getAverage(mites, 'mites') || getAverage(pollen, 'pollen'))
          setChartHData2([
            getAverage(getSensorHistoricData(2, "mold"), "mold"),
            getAverage(getSensorHistoricData(2, "dander"), "dander"),
            getAverage(getSensorHistoricData(2, "temperature"), "temperature"),
            getAverage(getSensorHistoricData(2, "mites"), "mites"),
            getAverage(getSensorHistoricData(2, "pollen"), "pollen"),
            getAverage(getSensorHistoricData(2, "humidity"), "humidity"),
            getAverage(getSensorHistoricData(2, "eco2"), "eco2"),
            getAverage(getSensorHistoricData(2, "voc"), "voc"),
          ]);
        } else {
          setChartHLabels2([
            "stuffiness",
            "density",
            "humid",
            "pollution",
            "transmission", //real data
            "temp.",
            "co2",
            "comfort", //fake data
          ]);
          setChartHData2(getDetailedChartTypeData(2));
        }
      }
    }
  }, [activeDeviceHistoryData2, detailedView]);

  //////////////////////////////////////////////////////////////////////////////////////////
  // Get devices
  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);
  ////////////////////////////// <<==== CONCENT STAGE ====>>> //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  // Get concerns
  useEffect(
    (cleanCleaningRequest) => {
      dispatch(
        fetchConcerns(
          userToken, // passing userToken as an argument
          cleanCleaningRequest, // passing cleanCleaningRequest as an argument
          // passing a callback function as an argument
          (cleanCleaningRequest) => {
            // Сallback function that will be called inside the fetchConcerns thunk.
            // This function takes cleanCleaningRequest as an argument and performs
            // setCleaningRequestInProgress(null) if cleanCleaningRequest is true.
            if (cleanCleaningRequest) {
              setCleaningRequestInProgress(null);
            }
          },
          dispatch
        )
      );
    },
    [dispatch]
  );
  //////////////////////////////////////////////////////////////////////////////////////////
  // Get new concerns
  useEffect(
    (cleanCleaningRequest) => {
      dispatch(
        // Callback function that is passed to thunk.
        // This function is called in the middle of fetchNewConcerns when the request is successful or unsuccessful.
        fetchNewConcerns(() => {
          if (cleanCleaningRequest) {
            setCleaningRequestInProgress(null);
          }
        })
      );
    },
    [modalType, dispatch]
  );
  //////////////////////////////////////////////////////////////////////////////////////////
  // Request cleaning
  const requestCleaning = () => {
    setCleaningRequestInProgress(activeDevice?.serial_number);
    dispatch(requestCleaning(activeDevice));
    dispatch(
      fetchNewConcerns(() => {
        setCleaningRequestInProgress(null);
      })
    );
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  // Mark as done (concerns)
  const markAsDone = async (id) => {
    const response = await dispatch(markConcernAsDone(id, userToken));
    if (response.error === false) {
      setSuccessSnackbarOpen(true);
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  // Mark as hidden (concerns)
  const markAsHidden = async (id) => {
    dispatch(hideConcern(id, userToken));
    // After the request is completed (whether it was successful or not), call fetchNewConcerns
    dispatch(fetchNewConcerns(dispatch));
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  // Get IoT DATA
  useEffect(() => {
    dispatch(fetchIotData(dispatch));
  }, [dispatch]);

  useEffect(() => {
    if (indoorData?.length) {
      const x = indoorData?.filter(
        (d) => d?.device_serial === activeDevice?.serial_number
      );
    }

    if (iotData?.length) {
      const t = iotData?.filter(
        (t) => t?.thing_name === activeDevice?.serial_number
      );
      // test.connectivity_connected
      // console.log('current device iot data: ', t)
      setActiveDeviceIotConnected(
        t ? t?.[0]?.connectivity_connected : undefined
      );
      if (t?.[0]) setActiveDeviceIotData(t?.[0]);
      else setActiveDeviceIotData(null);
    }
  }, [activeDeviceIndex]);

  useEffect(() => {
    _setNewRadarData();
  }, [activeDeviceIndoorData]);

  const _setNewRadarData = () => {
    setNewRadarData({
      labels: [
        "Transmission",
        "Stuffiness",
        "Pollution",
        "Humidity",
        "Discomfort",
        "Temperature",
        `${"  Density"}`,
        "Allergens",
      ],

      datasets: [
        {
          data: activeDeviceIndoorData?.indexes.map((i) => {
            return Math.round(i);
          }),
          backgroundColor: `rgba(122, 138, 255, 0.8)`,

          borderColor: "rgba(255, 99, 4, 1)",
          borderColor: "white",
          borderWidth: 0,
          pointBackgroundColor: "#A3B1FB",
          pointBackgroundColor: "transparent",
          pointBorderWidth: 0,
          fill: true,
        },
      ],
    });
  };

  useEffect(() => {
    setRadar({
      labels: chartHLabels1,
      labels: Array.from(chartHLabels1).splice(3, 1, "_  co2"),
      labels: chartHLabels1.map((element) => {
        if (element === "co2") {
          return "  co2";
        }
        return element;
      }),

      datasets: [
        {
          data: chartHData1, //

          backgroundColor: getChartDataColor(1),
          backgroundColor: `rgba(122, 138, 255, 0.8)`,

          borderColor: "rgba(255, 99, 4, 1)",
          borderColor: "white",
          borderWidth: 0,
          pointBackgroundColor: "#A3B1FB",
          pointBackgroundColor: "transparent",
          pointBorderWidth: 0,
          fill: true,
        },
      ],
    });
  }, [chartHData2, chartHData1]);

  const SmallIndexButton = ({ param, icon, loading }) => {
    return convertHDataToObj()?.map((d, idx) => {
      const Dot = ({ v, p }) => {
        return (
          <span
            className="Dot"
            style={{ background: getIndexColor(v), marginRight: 10 }}
          />
        );
      };

      const parameters = [param];
      if (parameters.includes(d?.name))
        return (
          <div key={idx} style={{ display: "flex", height: 32, width: "100%" }}>
            <div
              className="smallButton"
              style={{ opacity: chartLoading ? 0.5 : 1 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: loading ? "center" : "space-between",
                  flex: 1,
                  paddingRight: 20,
                  //     background: true? 'lightgrey' : undefined
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginRight: 5,
                    }}
                  >
                    <Dot p={d?.name} v={d?.value} />
                    {getShortIndexName(d?.value)}{" "}
                    {d?.name.charAt(0).toUpperCase() + d?.name.slice(1) ?? ""}
                  </div>
                  <div>{d?.value}</div>
                </div>
              </div>
            </div>
          </div>
        );
    });
  };

  const triggerDevicesReload = () => {
    setLoading(true);
    dispatch(fetchDevices());
    dispatch(fetchIndoorData());
    if (swiper) {
      swiper.slideTo(0);
    }
  };

  useEffect(() => {
    if (radar?.datasets && chartLoadingCounter < 1) {
      setChartLoading(false);
    }
  }, [radar]);

  const nowMode = tabSwitchValue === 2;

  useEffect(() => {
    setBackgroundRadar({
      labels: [
        "Transmission",
        "Stuffiness",
        "Pollution",
        "Humidity",
        "Discomfort",
        "Temperature",
        `${"  Density"}`,
        "Allergens",
      ],
      datasets: [
        {
          data: Array(8).fill(12),
          borderColor: "grey",
          backgroundColor: chartBackgroundPalette[0],
          fill: { value: 11 },
          z: -99,
          borderWidth: 0,
          pointRadius: 0,
          fontSize: 30,
        },
        {
          data: Array(8).fill(10),
          borderColor: "#F8D1C5",
          backgroundColor: chartBackgroundPalette[0],
          fill: { value: 8 },
          z: -99,
          borderWidth: 1.5,
          pointRadius: 0,
        },
        {
          data: Array(8).fill(8),
          borderColor: "#FED8BF",
          backgroundColor: chartBackgroundPalette[1],
          fill: { value: 6 },
          z: -99,
          pointRadius: 0,
        },
        {
          data: Array(8).fill(6),
          borderColor: "#BDE4C6",
          backgroundColor: chartBackgroundPalette[2],
          fill: { value: 4 },
          z: -99,
          pointRadius: 0,
        },
        {
          data: Array(8).fill(4),
          borderColor: "#92C746",
          backgroundColor: chartBackgroundPalette[3],
          fill: { value: 2 },
          z: -99,
          pointRadius: 0,
        },
        {
          data: Array(8).fill(2),
          borderColor: "transparent",
          backgroundColor: chartBackgroundPalette[4],
          fill: { value: 0 },
          z: -99,
          pointRadius: 0,
        },
      ],
    });
  }, [chartHLabels1]);

  const renderDevicesNames = () => {
    const ADD_DEVICE = "Add new device";
    const ORDER_DEVICE = "Order your Sensio Air device";

    let swiperDevices = devices ? JSON.parse(JSON.stringify(devices)) : [];
    swiperDevices.push({ name: ADD_DEVICE });
    swiperDevices.push({ name: ORDER_DEVICE });

    const addDevicePrompt = async () => {
      let deviceSerial = prompt(
        "Please enter device serial number",
        "6 digit code"
      );
      if (deviceSerial) {
        setButtonLoading(true);
        const res = await addDevice(deviceSerial.toUpperCase());
        if (res?.error === false) {
          alert("Device added successfully");
          setLoading(true);
          dispatch(fetchDevices());
          dispatch(fetchIndoorData());
          if (swiper) {
            swiper.slideTo(0);
          }
        } else {
          // alert(JSON.stringify(res));
        }
        setButtonLoading(false);
      }
    };

    return swiperDevices?.map(function (item, i) {
      let conditionColor = indoorData?.filter(
        (i) => i?.device_serial === item.serial_number
      );
      const isAddDevice = item?.name == ADD_DEVICE;
      const isOrderDevice = item?.name == ORDER_DEVICE;

      return (
        <SwiperSlide key={i}>
          {({ isActive }) => (
            <div
              key={i}
              className={`${
                "_" + conditionColor?.[0]?.condition
              } ${"myshadow"} ${"swiperDot"} ${
                isActive ? "activeSwiperDot" : ""
              } `}
            >
              <span
                style={{
                  position: "absolute",
                  paddingLeft: 20,
                  paddingRight: 20,
                  left: "2.5%",
                  opacity: i ? 1 : 0,
                  cursor: i ? "pointer" : undefined,
                }}
                onClick={() => {
                  swiper && swiper.slidePrev();
                }}
              >
                <img
                  src={triangle}
                  style={{
                    height: "calc((9vh - 30px)/3)",
                    transform: "rotate(180deg)",
                  }}
                />
              </span>
              <span
                style={{
                  position: "absolute",
                  paddingLeft: 20,
                  paddingRight: 20,
                  right: "2.5%",
                  opacity: i === swiperDevices?.length - 1 ? 0 : 1,
                  cursor:
                    i === swiperDevices?.length - 1 ? undefined : "pointer",
                }}
                onClick={() => {
                  swiper && swiper.slideNext();
                }}
              >
                <img
                  src={triangle}
                  style={{ height: "calc((9vh - 30px)/3)" }}
                />
              </span>

              <AddDeviceModal
                enabled={isAddDevice}
                setDeviceCode={setAddDeviceCode}
                triggerDevicesReload={triggerDevicesReload}
              >
                <div
                  onClick={() =>
                    isAddDevice && !buttonLoading
                      ? null //addDevicePrompt()
                      : isOrderDevice
                      ? window.open("http://sensioair.com/")
                      : null
                  }
                  className={`${styles.button_width} ${
                    isAddDevice || isOrderDevice
                      ? styles.swiperActionButton
                      : ""
                  }`}
                >
                  {buttonLoading && isAddDevice ? (
                    <CircularProgress color="primary" />
                  ) : (
                    item?.name ?? ""
                  )}
                </div>
              </AddDeviceModal>

              {}
            </div>
          )}
        </SwiperSlide>
      );
    });
  };

  const currentDeviceRequestingForCleaning =
    cleaningRequestInProgress === activeDevice?.name;
  const currentDeviceAlreadyCleaningRequested = concerns?.filter(
    (c) =>
      c?.type === "cleaning" &&
      c?.room === activeDevice?.name &&
      !c?.doneAt &&
      !c?.hidden
  );

  const dataLine = (data, color) => {
    if (!data || !data.length) return null;
    let labels = [];
    for (let i = 0; i < data?.length; i++) {
      labels.push(" ");
    }
    return {
      labels,
      datasets: [
        {
          data,
          borderColor: color ?? "grey",
          backgroundColor: "pink",
        },
      ],
    };
  };

  const tabsValuesNames = {
    0: "day",
    1: "week",
    2: "month",
  };

  const SmallButton2 = ({ onClick, text, icon, dotColor, led, rightIcon }) => {
    const backgroundPalette = [
      "lightgrey",
      "#E7615E",
      "#FFC161", //orange
      "#4FB340", //green
      "#4059D4", //blue
    ];

    const Dot = ({ v }) => {
      return (
        <span
          className="Dot"
          style={{
            background: dotColor
              ? isNaN(dotColor)
                ? dotColor
                : backgroundPalette[dotColor]
              : "transparent",
            border: led ? "1px grey solid " : undefined,
          }}
        />
      );
    };

    return (
      <div style={{ display: "flex", height: 32, width: "100%" }}>
        <div className="smallButton">
          <div
            style={{
              display: "flex",
              justifyContent: " space-between",
              alignItems: "center",
            }}
            onClick={onClick ?? null}
          >
            {rightIcon ? null : (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginRight: 5,
                }}
              >
                {icon ? icon : <Dot />}
              </div>
            )}

            <div>{text}</div>
            {rightIcon ? rightIcon : null}
          </div>
        </div>
      </div>
    );
  };

  const SmallButton = ({ onClick, text, icon, dotColor, led, rightIcon }) => {
    const backgroundPalette = [
      "lightgrey",
      "#E7615E",
      "#FFC161", //orange
      "#4FB340", //green
      "#4059D4", //blue
    ];

    const Dot = ({ v }) => {
      return (
        <span
          className="Dot"
          style={{
            background: dotColor
              ? isNaN(dotColor)
                ? dotColor
                : backgroundPalette[dotColor]
              : "transparent",
            border: led ? "1px grey solid " : undefined,
          }}
        />
      );
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: " space-between",
          alignItems: "center",
        }}
        onClick={onClick ?? null}
      >
        {rightIcon ? null : (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginRight: 5,
            }}
          >
            {icon ? icon : <Dot />}
          </div>
        )}

        <div>{text}</div>
        {rightIcon ? rightIcon : null}
      </div>
    );
  };

  const reportConcertTmp = () => {
    return (
      <div
        onClick={() => {
          setModalType("concern");
          setShowModal(true);
        }}
        style={{
          display: "flex",
          justifyContent: " space-between",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginRight: 5,
          }}
        >
          <ReportProblemIcon sx={{ color: "#FCE500" }} />
        </div>
        <div>Report a concern</div>
      </div>
    );
  };

  useEffect(() => {
    if (
      !userData?.country ||
      userData?.country?.toLowerCase() === "united states"
    ) {
      setTemperatureScale("imperial");
    } else {
      setTemperatureScale("metric");
    }
  }, [userData?.country]);

  const handleTemperature = (value) => {
    if (temperatureScale === "imperial") {
      return Math.round((value * 9) / 5 + 32) + "° F";
    } else {
      return Math.round(value) + "° C";
    }
  };

  const reportConcernModalBody = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          height: " 100%",
        }}
      >
        <span style={{ textAlign: "justify", fontSize: 14, marginTop: 20 }}>
          Let us know what the issue is and we will get back to you as soon as
          possible.
        </span>
        <ReportConcern
          //   uploadRes={setUploaderResponse}
          setSuccessSnackbarOpen={setSuccessSnackbarOpen}
          setLoadingProcess={setLoadingProcess}
          setLearnMore={setLearnMore}
          loadingProcess={loadingProcess}
          setShowModal={setShowModal}
          setModalType={setModalType}
          successSnackbarOpen={successSnackbarOpen}
          uploadRes={onUploaderResponse}
          deviceSerial={activeDevice?.serial_number}
          deviceName={activeDevice?.name}
        />
      </div>
    );
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState("");

  const handleImageClick = (imageUrl) => {
    setActiveImage(imageUrl);
    setModalOpen(true);
  };
  const showConcernsModalBody = () => {
    const Wrapper = ({ children }) => {
      return <div className="Wrapper__text">{children}</div>;
    };
    return (
      <div style={{ marginTop: 24 }}>
        {isLoading1 && <SLoader />}
        {newConcerns?.map((item, idx) => {
          return (
            <span key={idx}>
              <div className="concernRow">
                <div
                  className={
                    !item?.text && !item?.files ? "noDetails" : "details"
                  }
                  style={{ borderColor: item?.done ? "green" : "#FF8F85" }}
                >
                  <Accordion>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Wrapper>
                        <span
                          className="Dot"
                          style={{
                            marginRight: 5,
                            background: item?.done ? "green" : "#FF8F85",
                          }}
                        />
                        {item?.type === "cleaning"
                          ? `Cleaning Requested: `
                          : null}
                        {item?.deviceName?.replace("V3rC-00", "Room ")}
                      </Wrapper>
                    </AccordionSummary>
                    {item?.body || item?.files?.length ? (
                      <AccordionDetails>
                        <div style={{ height: 5 }} />
                        {item?.body ?? ""}
                        {item?.files ? (
                          <div className="imgsContainer">
                            {item?.files.map((item, idx) => {
                              return (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    handleImageClick(
                                      `${API_URL}/uploads/${item}`
                                    )
                                  }
                                  style={{
                                    backgroundImage: `url(${API_URL}/uploads/${item})`,
                                  }}
                                ></div>
                              );
                            })}
                          </div>
                        ) : null}
                      </AccordionDetails>
                    ) : null}
                  </Accordion>
                </div>

                <div
                  {...(item?.done ? { ...markAsHiddenLongPress(item) } : [])}
                  onClick={() => (item?.done ? null : markAsDone(item?._id))}
                  style={{
                    borderColor: item?.done ? "green" : "#FF8F85",
                  }}
                  className={`${item?.done ? "" : "markAsDone"}`}
                >
                  {item?.done ? (
                    <span className="Wrapper__done_text">
                      Done at{" "}
                      {moment(item?.done).local().format("MM/DD h:mm a")}
                    </span>
                  ) : (
                    <span
                      style={{ minWidth: 82.29, textAlign: "center" }}
                      className="Wrapper__done_text"
                    >
                      Mark as done
                    </span>
                  )}
                </div>
              </div>
            </span>
          );
        })}

        {isModalOpen && (
          <ImageModalPopap
            activeImage={activeImage}
            setModalOpen={setModalOpen}
          />
        )}
        {concerns.map((item, idx) => {
          if (true) return null;
          else
            return (
              <span key={idx}>
                <div
                  className="concernRow"
                  //  style={{  }}
                >
                  <div
                    className={
                      !item?.text && !item?.photos ? "noDetails" : "details"
                    }
                    style={{ borderColor: item?.doneAt ? "green" : "#FF8F85" }}
                  >
                    <Accordion>
                      <AccordionSummary
                        //  expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Wrapper>
                          <span
                            className="Dot"
                            style={{
                              marginRight: 5,
                              background: item?.doneAt ? "green" : "#FF8F85",
                            }}
                          />

                          {item?.type === "cleaning"
                            ? `Cleaning Requested: `
                            : null}
                          {item?.room.replace("V3rC-00", "Room ")}
                        </Wrapper>
                      </AccordionSummary>
                      {item?.text || item?.photos ? (
                        <AccordionDetails>
                          {item?.text ?? ""}
                          {item?.photos ? (
                            <div className="imgsContainer">
                              {JSON.parse(item?.photos).map((item, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    // style={{
                                    //   backgroundImage: `url(${concernsAPI_URL}/uploads/${item})`,
                                    // }}
                                  ></div>
                                );
                              })}
                            </div>
                          ) : null}
                        </AccordionDetails>
                      ) : null}
                    </Accordion>
                  </div>

                  <div
                    {...(item?.doneAt
                      ? { ...markAsHiddenLongPress(item) }
                      : [])}
                    onClick={() => (item?.doneAt ? null : markAsDone(item?.id))}
                    style={{
                      borderColor: item?.doneAt ? "green" : "#FF8F85",
                    }}
                    className={`${item?.doneAt ? "" : "markAsDone"}`}
                  >
                    {item?.doneAt ? (
                      <span style={{ fontSize: 10 }}>
                        Done at{" "}
                        {moment(parseInt(item?.doneAt)).format("MM/DD h:mm a")}
                      </span>
                    ) : (
                      "Mark as done"
                    )}
                    {/* {item?.id??''} */}
                  </div>
                </div>
              </span>
            );
        })}
      </div>
    );
  };

  const translateLabelsNames = (label) => {
    switch (label) {
      case "temp.":
        return "temperature";
      case "co2":
        return "eco2";
      case "humid":
        return "humidity";
      default:
        return label;
    }
  };

  const convertHDataToObj = () => {
    if (mergedDataTypesLabels?.length && mergedDataTypes?.length) {
      try {
        var arr = [];
        var i = 0;
        for (const label of mergedDataTypesLabels) {
          if (!arr.some((el) => el["name"] === label))
            arr.push({ name: label, value: mergedDataTypes[i] }); //remove dupes
          i++;
        }
        // console.log(arr)
        return arr;
      } catch (e) {
        console.log(e);
      }
    } else return null;
  };

  const detailViewModalBody = () => {
    return (
      <div>
        {convertHDataToObj()?.map((d, idx) => {
          const Dot = ({ v, p }) => {
            return (
              <span className="Dot" style={{ background: getIndexColor(v) }} />
            );
          };
          return (
            <div
              key={idx}
              style={{
                display: "flex",
                justifyContent: " space-between",
              }}
              className={"detailViewRow"}
            >
              <div>
                {d?.name.charAt(0).toUpperCase() + d?.name.slice(1) ?? ""}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    marginRight: 5,
                  }}
                >
                  <Dot p={d?.name} v={d?.value} />
                  {getShortIndexName(d?.value)}
                </div>
                <div>{d?.value}</div>
              </div>

              <div
                style={{
                  maxHeight: 40,
                  padding: "5px 10px",
                  opacity: nowMode ? 0.2 : 1,
                }}
              >
                {!nowMode &&
                getAverage(
                  getSensorHistoricData(1, translateLabelsNames(d?.name)),
                  translateLabelsNames(d?.name),
                  "lineData",
                  activeDeviceHistoryData1
                )?.length ? (
                  <Line
                    data={dataLine(
                      getAverage(
                        getSensorHistoricData(1, translateLabelsNames(d?.name)),
                        translateLabelsNames(d?.name),
                        "lineData",
                        activeDeviceHistoryData1
                      ),
                      getIndexColor(d?.value)
                    )}
                    options={optionsLine}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const screenSaver = () => {
    return (
      <CSSTransition
        nodeRef={nodeRef}
        in={idle}
        timeout={1000}
        classNames="screensaver"
      >
        <div
          ref={nodeRef}
          className={"screensaver"}
          style={{
            display: "flex",
            height: windowHeight,
            flex: "1",
            background: "#282727",
            position: "absolute",
            justifyContent: " space-around",
            alignItems: "center",
            flexDirection: "column",
            top: 0,
            left: 0,
          }}
        >
          {/* {activeDeviceIotData?.shadow?.reported?.Sensors?.Temperature && idle ?
              (parseInt(activeDeviceIotData?.shadow?.reported?.Sensors?.Temperature / 100) + '°C') : ''} */}
          {idle ? (
            <div
              style={{
                color: backgroundPalette[conditions],
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AirQuality
                color={
                  conditions ? backgroundPalette[conditions] : "transparent"
                }
              />
              <span style={{ height: 10 }} />
              {`${conditions ? "Air quality" : ""} ${
                conditions === 3
                  ? "excellent"
                  : conditions === 2
                  ? "average"
                  : conditions === 1
                  ? "poor"
                  : ""
              } `}
            </div>
          ) : null}
          {qrCodeLink && idle ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={qrCodeLink} style={{ width: 150, height: 150 }} />
              <span
                style={{ fontSize: 14, marginTop: 15, textAlign: "center" }}
              >
                Get this information
                <br />
                on your phone
              </span>
            </div>
          ) : null}

          {qrCodes &&
          idle &&
          qrCodes?.filter(
            (q) => q?.serialNumber === activeDevice?.serial_number
          ).length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${API_URL}/public/qr/${
                  qrCodes?.filter(
                    (q) => q?.serialNumber === activeDevice?.serial_number
                  )[0]?.fileName
                }`}
                style={{ width: 150, height: 150 }}
              />
              <span
                style={{ fontSize: 14, marginTop: 15, textAlign: "center" }}
              >
                Get this information
                <br />
                on your phone
              </span>
            </div>
          ) : null}
        </div>
      </CSSTransition>
    );
  };

  const loader = () => {
    if (loading) return sLoader(windowHeight);
  };

  const [isWidth450, setIsWidth450] = useState(window.innerWidth < 450);
  const [isHeigh900, setIsHeigh900] = useState(window.innerHeight < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsWidth450(window.innerWidth < 450);
      setIsHeigh900(window.innerWidth > 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const modals = () => {
    return (
      <StyledDialog
        open={showModal}
        onClose={() => {
          setLearnMore(false);
          setShowModal(false);
          setModalType(null);
        }}
        style={{
          maxWidth: 1600,
          maxHeight: !isHeigh900 ? 700 : "",
          margin: "auto",
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #8080807a",
              height: 60,
            }}
          >
            <div
              style={{
                margin: "10px 0 0 23px",
                fontSize: "calc(1.275rem + .3vw)",
              }}
            >
              {modalType === "concern"
                ? "Reporting Concern "
                : modalType === "showConcerns"
                ? "Concerns"
                : activeDeviceData?.device_data?.name ?? " "}{" "}
            </div>
            <IconButton
              edge="end"
              style={{
                width: 30,
                height: 30,
                margin: "12px 22px 0 23px",
                border: "2px solid grey",
              }}
              onClick={() => {
                setLearnMore(false);
                setShowModal(false);
                setModalType(null);
              }}
              aria-label="close"
            >
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            maxHeight:
              modalType === "showConcerns" ? (isWidth450 ? 486 : 547) : "",
          }}
        >
          {modalType === "learnMore" ? learnMoreModalBody() : null}
          {modalType === "concern" ? reportConcernModalBody() : null}
          {modalType === "showConcerns" ? showConcernsModalBody() : null}
          {modalType === "details" ? detailViewModalBody() : null}

          {modalType === "details" ? (
            <div onClick={() => setModalType("learnMore")} className="Button">
              Learn More
            </div>
          ) : null}
        </DialogContent>
      </StyledDialog>
    );
  };

  useEffect(() => {
    if (!swiperContainerSize || !chartContainerHeight) return;
    requestCleaningContainerRef?.current?.clientHeight > 5 &&
      setRequestCleaningContainerHeight(
        requestCleaningContainerRef?.current?.clientHeight
      );
    // }, )
  }, [chartContainerHeight]);

  //======================================================================================>>  START
  // Determining if this is a mobile version

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [isPhoneView, setIsPhoneView] = useState(window.innerWidth <= 400);
  const [isSmallPhoneView, setSmallIsPhoneView] = useState(
    window.innerWidth <= 351
  );
  const [testViewWidth, setTestViewWidth] = useState(window.innerWidth == 1080);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth == 1080) {
        setTestViewWidth(true);
      } else {
        setTestViewWidth(false);
      }

      if (window.innerWidth <= 800) {
        setIsMobileView(true);
      } else {
        if (viewType == 1) {
          setViewType(0);
        }
        setIsMobileView(false);
      }

      if (window.innerWidth <= 400) {
        setIsPhoneView(true);
      } else {
        setIsPhoneView(false);
      }

      if (window.innerWidth <= 351) {
        setSmallIsPhoneView(true);
      } else {
        setSmallIsPhoneView(false);
      }
    };

    // Add a window resizing event listener
    window.addEventListener("resize", handleResize);

    // The first initialization of the component, delete the listener when deleting the component
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //======================================================================================>>  END

  // (short for "Global Altitude Variable")
  const [GAV, setGAV] = useState(window.innerHeight >= 901);
  const [GAV1200, setGAV1200] = useState(window.innerHeight >= 1200);
  const [GAV1600, setGAV1600] = useState(window.innerHeight >= 1600);
  const [GAV3840, setGAV3840] = useState(window.innerHeight >= 2400);

  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight <= 630
  ); // Initial height 630px

  const [testViewHeight, setTestViewHeight] = useState(
    window.innerHeight >= 1920
  );

  const [testViewHeight2048, setTestViewHeight2048] = useState(
    window.innerHeight >= 2048
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerHeight >= 901) {
        setGAV(true);
      } else {
        setGAV(false);
      }

      if (window.innerHeight >= 1200) {
        setGAV1200(true);
      } else {
        setGAV1200(false);
      }

      if (window.innerHeight >= 2048) {
        setTestViewHeight2048(true);
      } else {
        setTestViewHeight2048(false);
      }

      if (window.innerHeight >= 2400) {
        setGAV3840(true);
      } else {
        setGAV3840(false);
      }

      if (window.innerHeight >= 1920) {
        setTestViewHeight(true);
      } else {
        setTestViewHeight(false);
      }

      if (window.innerHeight <= 630) {
        setContainerHeight(true);
      } else {
        setContainerHeight(false);
      }

      if (window.innerHeight >= 1600) {
        setGAV1600(true);
      } else {
        setGAV1600(false);
      }
    };

    // Add a window resizing event listener
    window.addEventListener("resize", handleWindowResize);

    // The first initialization of the component, delete the listener when deleting the component
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div
      {...handlers}
      className={`App${darkMode ? " darkMode" : ""} `}
      style={{
        height: windowHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingProcess}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          Successful operation!
        </Alert>
      </Snackbar>

      {screenSaverState && screenSaver()}
      <Helmet>
        <meta name="theme-color" content={idle ? "#282727" : "white"} />
      </Helmet>

      {loader()}

      {screenSaverState && screenSaver()}

      {modals()}

      {/*<<!========== Main body ==========>*/}
      <div style={{ width: "100%", display: "flex" }}>
        {/*<<!========== Left Side ==========>*/}
        <div
          style={{
            width:
              (testViewHeight && testViewWidth) || GAV == true
                ? "100%"
                : isMobileView
                ? "100%"
                : "50%",
          }}
        >
          {/*<<!========== Upper part ==========>*/}
          <div
            style={{
              opacity: loading ? 0 : 1,
              height:
                windowHeight - bottomContainerHeight - 15
                  ? windowHeight - bottomContainerHeight - 15
                  : 630,
            }}
            className={styles.top}
          >
            {/*<<!========== SWIPER CONTEINER ==========>*/}
            <div
              ref={swiperContainerRef}
              className={`${styles.swiperContainer} `}
              style={{
                height: "9vh",
                width: "100%",
                position: "absolute",
                fontSize: 20,
              }}
            >
              <Swiper
                allowTouchMove={!buttonLoading}
                onSwiper={setSwiper}
                centeredSlides={true}
                effect={"coverflow"}
                grabCursor={true}
                initialSlide={0}
                slidesPerView={1}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSlideChange={(s) => {
                  setActiveDeviceIndex(s.activeIndex);
                }}
              >
                {devices?.length || true ? (
                  renderDevicesNames()
                ) : (
                  <SwiperSlide>
                    <div
                      className={`${"myshadow"} ${"swiperDot"} ${"activeSwiperDot"}`}
                    >
                      <a
                        className={`${styles.button_width} `}
                        href="http://sensioair.com/"
                      >
                        Order your Sensio Air device
                      </a>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
            {/*<<!========== The first two parameters ==========>*/}
            <div
              className={`${styles.bigIndexBoxContainer}`}
              style={{
                height:
                  testViewHeight && testViewWidth == true ? 215 : undefined,
                marginTop: containerHeight ? 60 : "",
              }}
            >
              <BigIndexBox
                type={"Pathogens"}
                value={activeDeviceIndoorData?.indexes?.[7] ?? 0}
                testViewHeight={testViewHeight}
                testViewWidth={testViewWidth}
              />
              <BigIndexBox
                type={"Pollutants"}
                value={activeDeviceIndoorData?.indexes?.[2] ?? 0}
                testViewHeight={testViewHeight}
                testViewWidth={testViewWidth}
              />
            </div>
            {/*<<!========== Options that are hidden if it is not a mobile device  ==========>*/}
            {/* // You can see them by swiping to the right */}
            <div
              style={{
                // Parameters responsible for visibility
                opacity: !viewType ? 0 : 1,
                maxHeight: !viewType ? 0 : undefined,
                maxWidth: !viewType ? 0 : undefined,
                //
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <div
                style={{ marginTop: 15 }}
                className={`${styles.barIndicatorContainer} myshadow`}
              >
                <BarIndicator
                  type={"Pollution"}
                  isMobileView={isMobileView}
                  value={activeDeviceIndoorData?.indexes?.[2] * 10}
                />
              </div>
              <div className={`${styles.barIndicatorContainer} myshadow`}>
                <BarIndicator
                  type={"Transmission"}
                  isMobileView={isMobileView}
                  value={activeDeviceIndoorData?.indexes?.[0] * 10}
                />
              </div>
              <div className={`${styles.barIndicatorContainer} myshadow`}>
                <BarIndicator
                  isMobileView={isMobileView}
                  type={"Stuffiness"}
                  value={activeDeviceIndoorData?.indexes?.[1] * 10}
                />
              </div>
              <div className={`${styles.barIndicatorContainer} myshadow`}>
                <BarIndicator
                  onValueClick={() =>
                    setTemperatureScale(
                      temperatureScale == "imperial" ? "metric" : "imperial"
                    )
                  }
                  isMobileView={isMobileView}
                  isPhoneView={isPhoneView}
                  type={"Temperature"}
                  value={activeDeviceIndoorData?.indexes?.[5] * 10}
                  rawValue={
                    isNaN(
                      Math.round(activeDeviceIndoorData?.rawData?.temperature)
                    )
                      ? " - "
                      : handleTemperature(
                          activeDeviceIndoorData?.rawData?.temperature
                        )
                  }
                />
              </div>
              <div className={`${styles.barIndicatorContainer} myshadow`}>
                <BarIndicator
                  isMobileView={isMobileView}
                  type={"Discomfort"}
                  value={activeDeviceIndoorData?.indexes?.[4] * 10}
                />
              </div>
              <div className={`${styles.barIndicatorContainer} myshadow`}>
                <BarIndicator
                  isMobileView={isMobileView}
                  type={"Allergens"}
                  value={activeDeviceIndoorData?.indexes?.[7] * 10}
                />
              </div>
            </div>
            {/*<<!========== RADAR CONTAINER ==========>*/}
            <div
              ref={chartContainerRef}
              style={{
                flex: 5,
                justifyContent: "center",
                display: "flex",
                padding: `15px 0 0`,
                opacity: viewType ? 0 : 1,
                maxHeight: viewType
                  ? 0
                  : GAV1200
                  ? "40%"
                  : testViewHeight2048
                  ? 625
                  : GAV
                  ? "64%"
                  : "",
                maxWidth: viewType ? 0 : undefined,
              }}
            >
              {noData && false ? (
                <div
                  className="NoData"
                  style={{ height: chartContainerHeight }}
                >
                  <span>no data</span>
                </div>
              ) : null}

              {newRadarData?.datasets &&
              chartContainerHeight &&
              swiperContainerSize ? (
                <div
                  className={`${styles.chartsContainer} ${styles.myshadow}`}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    position: "relative",
                    margin:
                      testViewHeight && testViewWidth == true
                        ? "unset"
                        : "auto",
                    height: "100%",
                    width: "100%",
                    padding:
                      chartContainerHeight > chartContainerWidth
                        ? "0 15px"
                        : undefined,
                  }}
                >
                  {chartLoading ? (
                    <div
                      className="chartLoading"
                      style={{ height: chartContainerHeight }}
                    >
                      <span>
                        <CircularProgress size={33} />
                      </span>
                    </div>
                  ) : null}
                  <Radar
                    data={backgroundRadar}
                    options={
                      testViewHeight && testViewHeight == true
                        ? backgroundRadarOptionsTest
                        : backgroundRadarOptions
                    }
                    ref={chartRef}
                  />
                  {radar?.datasets &&
                  !chartLoading &&
                  activeDeviceIndoorData ? (
                    <Radar
                      data={newRadarData}
                      options={radarOptions}
                      ref={chartRef}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            {/* TEST */}
            {GAV1200 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  height: "45%",
                }}
              >
                <div
                  className={`${styles.barIndicatorContainer} myshadow`}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Air Quality"}
                    value={
                      ((activeDeviceIndoorData?.indexes?.[7] +
                        activeDeviceIndoorData?.indexes?.[1]) /
                        2) *
                      10
                    }
                  />
                </div>
                <div
                  className={`${styles.barIndicatorContainer} myshadow`}
                  style={{ fontSize: "1.1vh" }}
                >
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Pollution"}
                    value={activeDeviceIndoorData?.indexes?.[2] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Transmission"}
                    value={activeDeviceIndoorData?.indexes?.[0] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Stuffiness"}
                    value={activeDeviceIndoorData?.indexes?.[1] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Temperature"}
                    value={activeDeviceIndoorData?.indexes?.[5] * 10}
                    rawValue={handleTemperature(
                      activeDeviceIndoorData?.rawData?.temperature
                    )}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Discomfort"}
                    value={activeDeviceIndoorData?.indexes?.[4] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    GAV3840={GAV3840}
                    GAV1200={GAV1200}
                    testViewHeight={testViewHeight}
                    testViewWidth={testViewWidth}
                    testViewHeight2048={testViewHeight2048}
                    type={"Allergens"}
                    value={activeDeviceIndoorData?.indexes?.[7] * 10}
                  />
                </div>
              </div>
            )}
            {/*<<!========== Container with the parameter ==========>*/}
            {!viewType ? (
              <>
                {(GAV || isMobileView) && !GAV1200 && (
                  <div
                    className={`${styles.barIndicatorContainer} myshadow QWE`}
                    style={{ marginTop: isMobileView || GAV ? 10 : 0 }}
                  >
                    <BarIndicator
                      type={"Air Quality"}
                      value={
                        ((activeDeviceIndoorData?.indexes?.[7] +
                          activeDeviceIndoorData?.indexes?.[1]) /
                          2) *
                          10 || undefined
                      }
                    />
                  </div>
                )}
                <div
                  {...reportConcernLongPress()}
                  ref={requestCleaningContainerRef}
                  onClick={
                    null
                    // cleaningRequested || cleaningRequestInProgress
                    //   ? null
                    //   : devices?.length
                    //   ? requestCleaning()
                    //   : null
                  }
                  className={`myshadow myShadow__Container`}
                  style={{
                    marginLeft:
                      testViewHeight && testViewWidth == true
                        ? 25
                        : GAV3840
                        ? "0.0%"
                        : "2.5%",
                    marginRight:
                      testViewHeight && testViewWidth == true ? 25 : "2.5%",
                    borderRadius: "10px",
                    marginTop:
                      isMobileView || GAV
                        ? "3px"
                        : testViewHeight && testViewWidth
                        ? 0
                        : "15px",
                    display: "flex",
                    flex: testViewHeight && testViewWidth == true ? 0 : 0.6,
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: isPhoneView ? "6px 12px" : "6px 12px",
                    opacity: devices?.length ? 1 : 0.25,
                    height: testViewHeight && testViewWidth ? 80 : "",
                  }}
                >
                  <img
                    src={
                      activeDevice?.serial_number
                        ? PUBLIC_URL +
                          `qr/` +
                          activeDevice?.serial_number +
                          ".png"
                        : ""
                    }
                    width={
                      requestCleaningContainerHeight - 12 > 0
                        ? (requestCleaningContainerHeight - 12).toString()
                        : 0
                    }
                    style={{
                      opacity: 0,
                    }}
                  />
                  {cleaningRequestInProgress ? (
                    <CircularProgress size={20} />
                  ) : (
                    <span
                      style={{
                        opacity: cleaningRequested ? 0.5 : 1,
                        fontSize: isSmallPhoneView ? 14 : GAV3840 ? 46 : 22,
                        paddingLeft:
                          testViewHeight && testViewWidth == true ? 31 : "",
                      }}
                    >
                      {cleaningRequested
                        ? "Cleaning requested"
                        : "Request Cleaning"}
                    </span>
                  )}
                  <Dialog
                    open={qrCodeDialog}
                    onClose={() => setQrCodeDialog(false)}
                  >
                    <DialogContent>
                      <img
                        src={
                          PUBLIC_URL +
                          `qr/` +
                          activeDevice?.serial_number +
                          ".png"
                        }
                      />
                    </DialogContent>
                  </Dialog>
                  <img
                    onClick={() =>
                      activeDevice?.serial_number ? setQrCodeDialog(true) : null
                    }
                    src={
                      PUBLIC_URL + `qr/` + activeDevice?.serial_number + ".png"
                    }
                    width={
                      requestCleaningContainerHeight - 12 > 0
                        ? (requestCleaningContainerHeight - 12).toString()
                        : 0
                    }
                    style={{
                      height: null - 12,
                      opacity: activeDevice?.serial_number ? 1 : 0,
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>

          {/*<<!========= Lower part =========>>*/}
          <div
            ref={bottomRefPassthrough}
            style={{
              justifyContent: !detailedView ? "space-between" : undefined,
              opacity: loading ? 0 : 1,
              alignItems: "center",
              display: "flex",
              marginTop: 10,
              width: "100%",
              position: containerHeight ? "unset" : "absolute",
            }}
            className={`${styles.bottom} `}
          >
            <div
              style={{
                color: "#3337ED",
                textAlign: "center",
                fontSize: "1.8vh",
                lineHeight: "16px",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "flex-end",
                width: chartContainerWidth ?? 0,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: testViewHeight && testViewWidth == true ? 0 : 10,
                paddingBottom: 5,
              }}
              className=""
            >
              <div
                style={{ opacity: testViewWidth ? 0 : 1 }}
                {...showConcernsLongPress()}
              >
                <Dots dotsCount={2} activeIndex={viewType} />
              </div>
              <span
                style={{
                  paddingTop: testViewHeight && testViewWidth == true ? 10 : 5,
                  fontSize: testViewHeight && testViewWidth == true ? 23 : "",
                }}
                {...showConcernsLongPress()}
              >
                <span>www.sensioair.com</span>
              </span>
            </div>
          </div>
        </div>

        {/*<<!========== RIGHT SIDE ==========>*/}
        {/* IF isMobileView = false */}
        {/* IF GAV = true if the height is greater than or equal to 901 px */}
        <>
          {!isMobileView && !GAV && (
            <div
              style={{
                width: "50%",
                marginTop: containerHeight ? 21 : "",
              }}
            >
              {/*<<!========== Options that are hidden if it is not a mobile device  ==========>*/}
              <div
                style={{
                  height: containerHeight
                    ? 630
                    : windowHeight - bottomContainerHeight - 15,
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflowY: "auto",
                }}
              >
                <div
                  className={`${styles.barIndicatorContainer} myshadow`}
                  style={{ marginTop: "9vh" }}
                >
                  <BarIndicator
                    type={"Pollution"}
                    value={activeDeviceIndoorData?.indexes?.[2] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    type={"Transmission"}
                    value={activeDeviceIndoorData?.indexes?.[0] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    type={"Stuffiness"}
                    value={activeDeviceIndoorData?.indexes?.[1] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    type={"Temperature"}
                    value={activeDeviceIndoorData?.indexes?.[5] * 10}
                    rawValue={handleTemperature(
                      activeDeviceIndoorData?.rawData?.temperature
                    )}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    type={"Discomfort"}
                    value={activeDeviceIndoorData?.indexes?.[4] * 10}
                  />
                </div>
                <div className={`${styles.barIndicatorContainer} myshadow`}>
                  <BarIndicator
                    type={"Allergens"}
                    value={activeDeviceIndoorData?.indexes?.[7] * 10}
                  />
                </div>
                <div
                  className={`${styles.barIndicatorContainer} myshadow`}
                  style={{ marginBottom: 2 }}
                >
                  <BarIndicator
                    type={"Air Quality"}
                    value={
                      ((activeDeviceIndoorData?.indexes?.[7] +
                        activeDeviceIndoorData?.indexes?.[1]) /
                        2) *
                      10
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Data;
