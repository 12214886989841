import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import uiSlice from "./slices/uiSlice";
import loggerMiddleware from "redux-logger"; // Import Redux Logger
import usersSlice from "./slices/usersSlice";
import devicesSlice from "./slices/devicesSlice";
import indoorDataSlice from "./slices/indoorDataSlice";
import qrSlice from "./slices/qrSlice";
import iotDataSlice from "./slices/iotDataSlice";
import concernsSlice from "./slices/concernsSlice";
import newConcernsSlice from "./slices/newConcernsSlice";
import cleaningRequestSlice from "./slices/cleaningRequestSlice";
import concernDoneSlice from "./slices/concernDoneSlice";
import hiddenConcernSlice from "./slices/hiddenConcernSlice";
import errorSlice from "./slices/errorSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    ui: uiSlice,
    users: usersSlice,
    devices: devicesSlice,
    indoorData: indoorDataSlice,
    qr: qrSlice,
    iotData: iotDataSlice,
    concerns: concernsSlice,
    newConcerns: newConcernsSlice,
    requestCleaning: cleaningRequestSlice,
    concernDone: concernDoneSlice,
    hiddenConcern: hiddenConcernSlice,
    error: errorSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      process.env.REACT_APP_MODE === "local" ? loggerMiddleware : []
    ); // Add Redux Logger
  },
});
