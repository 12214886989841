import { createSlice } from "@reduxjs/toolkit";

export const qrSlice = createSlice({
  name: "qr",
  initialState: {
    qrCodes: [],
    qrCodeLink: "",
    loading: false,
    error: null,
  },
  reducers: {
    fetchQrStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchQrSuccess: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.qrCodes = action.payload;
      } else {
        state.qrCodeLink = action.payload;
      }
      state.loading = false;
    },
    fetchQrFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchQrStart, fetchQrSuccess, fetchQrFailure } = qrSlice.actions;

export const selectQr = (state) => state.qr;

export default qrSlice.reducer;
