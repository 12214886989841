import { API_URL } from "../consts";
import wretch from "wretch";
import { setError } from "../store/slices/errorSlice";

export function useWretchPut(dispatch) {
  const createWretchInstance = async (url = "", body = {}, options = {}) => {
    const fullUrl = url ? `${API_URL}${url}` : API_URL;

    try {
      const response = await wretch()
        .url(fullUrl)
        .options({
          ...options,
          credentials: "include",
        })
        .json(body)
        .put()
        .notFound((error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .unauthorized((error) => {
          // localStorage.removeItem("token");
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .error(418, (error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .res();

      if (!response.ok) {
        throw new Error("An error occurred while updating the data");
      }

      return await response.json();
    } catch (error) {
      try {
        const parsedError = JSON.parse(error?.message);
        dispatch(
          setError({ message: parsedError?.message, status: error?.status })
        );
      } catch (parsingError) {
        // If JSON parsing fails, it may be a connection issue
        dispatch(
          setError({ message: "Service Unavailable", status: 503 }) // 503 - Service Unavailable
        );
      }
      throw error;
    }
  };

  return createWretchInstance;
}
