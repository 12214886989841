import { API_URL } from "../consts";
import FormDataAddon from "wretch/addons/formData";
import wretch from "wretch";

import { setError } from "../store/slices/errorSlice";

export function useWretchPost(dispatch) {
  const createWretchInstance = async (
    url = "",
    body = {},
    options = {},
    useFormData = false
  ) => {
    // We can pass an additional url as a parameter, and it will supplement the base API_URL.
    const fullUrl = url ? `${API_URL}${url}` : API_URL;

    const token = localStorage.getItem("token");

    let request = wretch()
      .url(fullUrl)
      .options({
        ...options,
        credentials: "include",
      });

    if (token) {
      request = request.auth(`Bearer ${token}`);
    }

    if (useFormData) {
      request = request.addon(FormDataAddon).formData(body);
    } else {
      request = request.json(body);
    }

    try {
      const response = await request
        .post()
        .notFound((error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .unauthorized((error) => {
          localStorage.removeItem("token");
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          window.location.href = "/";
        })
        .error(418, (error) => {
          const parsedError = JSON.parse(error?.message);
          dispatch(
            setError({ message: parsedError?.message, status: error?.status })
          );
          throw error;
        })
        .res();

      // Check if the response is okay, otherwise throw an error
      if (!response.ok) {
        throw new Error("An error occurred while posting the data");
      }

      return await response.json(); // Parsing and returning the JSON response body.
    } catch (error) {
      try {
        const parsedError = JSON.parse(error?.message);
        dispatch(
          setError({ message: parsedError?.message, status: error?.status })
        );
      } catch (parsingError) {
        // If JSON parsing fails, it may be a connection issue
        // localStorage.removeItem("token");
        dispatch(
          setError({ message: "Service Unavailable", status: 503 }) // 503 - Service Unavailable
        );
        // window.location.href = "/";
      }
      throw error;
    }
  };

  return createWretchInstance;
}
