import { createSlice } from "@reduxjs/toolkit";

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    value: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchDevicesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDevicesSuccess: (state, action) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchDevicesFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchDevicesStart, fetchDevicesSuccess, fetchDevicesFailure } =
  devicesSlice.actions;

export const selectDevices = (state) => state.devices.value;

export default devicesSlice.reducer;
