export const AirQuality = ({color}) => {

    return (
            <svg width="91px" height="91px" viewBox="0 0 91 91" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <g id="Group-2">
                    <g id="favorite-house-copy">
                        <path d="M80.8257 91L10.1637 91C9.42858 91 8.83294 90.4065 8.83294 89.6741L8.83294 44.8469L1.33078 44.8469C0.787312 44.8469 0.298666 44.5181 0.0958621 44.0158C-0.10641 43.5136 0.0176149 42.9392 0.409383 42.5643L44.5733 0.369119C45.0886 -0.12304 45.9003 -0.12304 46.4156 0.369119L90.4837 42.4725C90.7977 42.7154 91 43.0946 91 43.5216C91 44.254 90.4044 44.8474 89.6693 44.8474C89.665 44.8469 89.6618 44.8469 89.6586 44.8474L82.1565 44.8474L82.1565 89.6747C82.1565 90.4065 81.5608 91 80.8257 91ZM11.6917 88.4299L79.0693 88.4299L79.0693 43.7981C79.0693 43.0689 79.6594 42.4781 80.3878 42.4781L85.8598 42.4781L45.3805 3.61609L4.90115 42.4781L10.3731 42.4781C11.1015 42.4781 11.6917 43.0689 11.6917 43.7981L11.6917 88.4299ZM45.3805 74.5333C45.314 74.5333 45.2471 74.5281 45.1812 74.5186C44.9767 74.487 40.0786 73.6812 31.6003 67.4044C22.9781 61.0219 22.5 53.9325 23.0472 48.4695C23.6049 42.9023 27.9268 37.2667 35.1083 37.2667C40.1672 37.2667 43.7232 40.019 45.3805 41.6417C47.0377 40.019 50.5937 37.2667 55.6526 37.2667C62.8342 37.2667 67.1561 42.9023 67.7138 48.4695C68.2609 53.9325 67.7828 61.0219 59.1606 67.405C50.6823 73.6812 45.7842 74.487 45.5797 74.5181C45.5138 74.5281 45.4469 74.5333 45.3805 74.5333ZM35.0787 39.8368C29.1846 39.8368 26.0396 44.4371 25.6124 48.7118C25.0779 54.0588 25.6193 59.7756 33.1342 65.3485C40.0777 70.4983 44.4122 71.7358 45.3813 71.9632C46.3381 71.739 50.6748 70.5047 57.6267 65.349C65.1416 59.7756 65.683 54.0588 65.1485 48.7118C64.7208 44.4371 61.5763 39.8368 55.6823 39.8368C49.9769 39.8368 46.464 44.3065 46.4291 44.352C46.179 44.6762 45.791 44.8682 45.3818 44.8682C44.9668 44.8671 44.5782 44.6762 44.3292 44.3488C44.297 44.3065 40.7835 39.8368 35.0787 39.8368Z" id="Shape" fill={color} stroke="none" />
                    </g>
                    <path d="M35.0787 39.8368C29.1847 39.8368 26.0397 44.4372 25.6125 48.7118C25.078 54.0589 25.6194 59.7756 33.1343 65.3485C40.0778 70.4983 44.4123 71.7358 45.3813 71.9632C46.3382 71.739 50.6749 70.5047 57.6268 65.349C65.1417 59.7756 65.6831 54.0589 65.1486 48.7118C64.7209 44.4372 61.5764 39.8368 55.6824 39.8368C49.977 39.8368 46.464 44.3065 46.4292 44.352C46.1791 44.6762 45.7911 44.8682 45.3819 44.8682C44.9669 44.8671 44.5783 44.6762 44.3293 44.3488C44.2971 44.3065 40.7836 39.8368 35.0787 39.8368Z" id="Path" fill={color} stroke="none" />
                </g>
            </svg>
    )
}