import { useWretchGet } from "../../hooks/useWretchGet";
import {
  fetchDevicesFailure,
  fetchDevicesStart,
  fetchDevicesSuccess,
} from "../slices/devicesSlice";

export const fetchDevices = () => async (dispatch) => {
  const get = useWretchGet(dispatch);
  dispatch(fetchDevicesStart());
  try {
    const response = await get(`/device/`);
    if (!response?.error) {
      dispatch(fetchDevicesSuccess(response?.message));
    } else {
      dispatch(fetchDevicesFailure("Failed to fetch devices."));
    }
  } catch (error) {
    dispatch(fetchDevicesFailure(error.toString()));
  }
};
