export const swipeableConfig = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
  trackTouch: true, // track touch input
  trackMouse: true, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
};

// export const chartBackgroundPalette =
// [
//     '#F2F2F3',
//     '#E9E8E9',
//     '#E7DDD8',
//     '#D9CBC5',
//     '#D7CECB'
// ]

// export const chartBackgroundPalette =
// [
//     '#D89A9A',
//     '#C19D8C',
//     '#93A36F',
//     '#4DAC45',
//     '#1FB228'
// ]

export const chartBackgroundPalette = [
  "rgba(255, 255, 255, 1)",
  "rgba(255, 255, 255, 1)",
  "rgba(255, 255, 255, 1)",
  "rgba(255, 255, 255, 1)",
  "rgba(255, 255, 255, 1)",
];

export const backgroundRadarOptionsTest = {
  aspectRatio: 1.1,
  animation: {
    duration: 0,
  },
  layout: {
    padding: 0,
  },
  scales: {
    r: {
      ticks: {
        backdropPadding: 0,
        backdropColor: "transparent",
        font: {
          size: 14,
        },
        z: 1,
        stepSize: 1,
        display: true,
        color: "transparent",
        //     case 100:
        //       return 'red'
        //     case 50:
        //       return 'orange'
        //     case 10:
        //       return 'green'
        //     default:
        //       return 'black'
        //   }
        // },

        callback: function (value, index, ticks) {
          const numbers = [0, 1, 2, 5, 10, 10, 50, 100];
          if (numbers.indexOf(value) !== -1) return value;
        },
      },
      border: {
        z: 11,
        width: 0,
        color: "transparent",
      },
      pointLabels: {
        color: "#585858",
        font: { size: 24, family: "Avenir Next Cyr Medium" },
      },
      angleLines: {
        color: "grey",
        z: 5,
      },
      grid: {
        color: "white",
        z: 99,
        display: false,
        drawOnChartArea: false,
      },
      min: 1,
      max: 12,
    },
  },
  plugins: {
    legend: {
      display: false,
      // labels: {
      //             color: "blue",
      //             fontSize: 18
      //         }
    },
  },
  legend: {
    display: false,
    // labels: {
    //             color: "blue",
    //             fontSize: 18
    //         }
  },
};

export const backgroundRadarOptions = {
  aspectRatio: 1.1,
  animation: {
    duration: 0,
  },
  layout: {
    padding: 0,
  },
  scales: {
    r: {
      ticks: {
        backdropPadding: 0,
        backdropColor: "transparent",
        font: {
          size: 14,
        },
        z: 1,
        stepSize: 1,
        display: true,
        color: "transparent",
        //     case 100:
        //       return 'red'
        //     case 50:
        //       return 'orange'
        //     case 10:
        //       return 'green'
        //     default:
        //       return 'black'
        //   }
        // },

        callback: function (value, index, ticks) {
          const numbers = [0, 1, 2, 5, 10, 10, 50, 100];
          if (numbers.indexOf(value) !== -1) return value;
        },
      },
      border: {
        z: 11,
        width: 0,
        color: "transparent",
      },
      pointLabels: {
        color: "#585858",
        font: { size: 14, family: "Avenir Next Cyr Medium" },
      },
      angleLines: {
        color: "grey",
        z: 5,
      },
      grid: {
        color: "white",
        z: 99,
        display: false,
        drawOnChartArea: false,
      },
      min: 1,
      max: 12,
    },
  },
  plugins: {
    legend: {
      display: false,
      // labels: {
      //             color: "blue",
      //             fontSize: 18
      //         }
    },
  },
  legend: {
    display: false,
    // labels: {
    //             color: "blue",
    //             fontSize: 18
    //         }
  },
};

export const backgroundRadarOptions2 = {
  animation: {
    duration: 0,
  },
  layout: {
    padding: 0,
  },
  scales: {
    r: {
      ticks: {
        display: false,
      },
      border: {
        z: 11,
        width: 0,
      },
      pointLabels: {
        color: "transparent",
      },
      angleLines: {
        color: "transparent",
        z: 5,
      },
      grid: {
        color: "transparent",
        z: 99,
        drawOnChartArea: false,
      },
      min: 1,
      max: 101,
    },
  },
  plugins: {
    legend: {
      display: false,
      // labels: {
      //             color: "blue",
      //             fontSize: 18
      //         }
    },
  },
  legend: {
    display: false,
    // labels: {
    //             color: "blue",
    //             fontSize: 18
    //         }
  },
};

export const radarOptions = {
  aspectRatio: 1.1,
  layout: {
    padding: -10,
  },
  animation: {
    duration: 250,
  },
  scales: {
    r: {
      maxRotation: 90,
      minRotation: 80,
      beginAtZero: true,
      display: true,
      ticks: {
        backdropPadding: 0,
        backdropColor: "transparent",
        font: {
          size: 14,
        },
        z: 99,
        stepSize: 1,
        display: true,
        // color: 'red',
        color: (context) => {
          switch (context?.tick?.value) {
            case 10:
              return "red";
            case 5:
              return "orange";
            case 1:
              return "green";
            default:
              return "black";
          }
        },

        callback: function (value, index, ticks) {
          const numbers = [0, 1, 5, 10];
          if (numbers.includes(value)) return value;
        },
      },
      border: {},
      pointLabels: {
        color: "transparent",
        font: { size: 14, family: "Avenir Next Cyr Medium" },
      },
      angleLines: {
        color: "transparent",
        z: -2,
      },
      grid: {
        color: "transparent",
        z: -2,
        drawOnChartArea: false,
      },
      min: 0,
      max: 12,
    },
  },
  plugins: {
    legend: {
      display: false,
      // labels: {
      //             color: "blue",
      //             fontSize: 18
      //         }
    },
  },
  legend: {
    display: false,
    // labels: {
    //             color: "blue",
    //             fontSize: 18
    //         }
  },
};

export const labelsLine = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const optionsLine = {
  animation: {
    duration: 0,
  },

  responsive: true,
  aspectRatio: 3,
  pointRadius: 0,
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};
