import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { updateWindowHeight } from "./store/slices/uiSlice";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { SLoader } from "./components/sLoader/sLoader";
import QrAccess from "./QrAccess";
import Auth from "./Auth";
import VerifyEmail from "./VerifyEmail";
import Data from "./Data";
import Tenant from "./Tenant";
import Protected from "./Protected";
import ReactGA from "react-ga4";
import { updateAuth, updateUser } from "./store/slices/authSlice";
import AdminPage from "./pages/AdminPage";
import PasswordResetForm from "./pages/PasswordResetForm";
import UserDetailsPage from "./pages/UserDetailsPage";
import { useWretchGet } from "./hooks/useWretchGet";
import { API_URL } from "./consts";
import wretch from "wretch";
/**
 * - Setting up routing;
 * - Initialization of Google Analytics;
 * - Checking user authorization;
 * - Monitor the height of the browser window;
 */

export default function App() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [authChecked, setAuthChecked] = useState(false);
  const [token, setToken] = useState(undefined);
  const navigate = useNavigate();

  ///////////////////////////////////////////////////////////////////////////////////////
  // Track and update the height of the browser window when it changes and store this value in the Redux storage
  useEffect(() => {
    const handleWindowResize = () => {
      dispatch(updateWindowHeight({ windowHeight: window.innerHeight }));
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch]);

  useEffect(() => {
    // One-time effect for the initial value
    dispatch(updateWindowHeight({ windowHeight: window.innerHeight }));
  }, [dispatch]);

  ///////////////////////////////////////////////////////////////////////////////////////
  // Initializing the ReactGA library with a Google Analytics tracking ID
  useEffect(() => {
    ReactGA.initialize("G-Y8CXNDGNBH");
    ReactGA.send({
      hitType: "pageview",
      page: "/my-path",
      title: "Custom Title",
    });
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  // Checking user authorization

  useEffect(() => {
    const token = localStorage.getItem("token");
    const get = useWretchGet(dispatch);

    const handleError = (error) => {
      if (error?.message === "Unauthorized") {
        //console.log("Unauthorized");
      } else if (error?.message === "Failed to fetch") {
        //console.log("Failed to fetch");
      } else {
        console.log(error);
      }
    };

    const checkAuth = async () => {
      try {
        const res = await wretch(API_URL + "/auth/check")
          .options({ withCredentials: true, credentials: "include" })
          .get()
          .res(async (response) => {
            if (response.ok) {
              const json = await response.json();
              if (!json?.error && json?.user) {
                dispatch(updateAuth(true));
                dispatch(updateUser(json?.user));
              }
            }
            setAuthChecked(true);
          })
          .catch(handleError);
      } catch (error) {
        console.log(error);
      } finally {
        setAuthChecked(true);
      }
    };

    checkAuth();
  }, []);

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  if (!authChecked) return <SLoader />;
  else if (true)
    return (
      <GoogleOAuthProvider
        clientId={`854822458829-6820rm4si6vq7uhos1e3ksfc33qsnvnn.apps.googleusercontent.com`}
      >
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Protected auth={isAuthenticated}>
                  <Data />
                </Protected>
              ) : (
                <Auth />
              )
            }
          ></Route>

          <Route path="a">
            <Route path=":data" element={<Auth />} />
            <Route
              path="/a"
              element={
                <div style={centerStyle}>
                  request access via qr code or{"\u00A0"}
                  <Link to={"/login"}>log in</Link>
                </div>
              }
            />
          </Route>
          {/* <Route path="getAccess">
            <Route path=":iv/:content" element={<QrAccess />} />
            <Route
              path="/getAccess"
              element={
                <div style={centerStyle}>
                  request access via qr code or{"\u00A0"}
                  <Link to={"/login"}>log in</Link>
                </div>
              }
            />
          </Route> */}
          <Route path="auth">
            <Route path="/auth" element={<Auth />} />
            <Route path="/auth:iv/:content" element={<Auth />} />
            <Route
              path="/auth/reset-password/:resetPasswordEmail/:resetPasswordHash"
              element={<Auth />}
            />
            <Route
              path="verifyemail/:userId/:verificationCode"
              element={<VerifyEmail />}
            />
          </Route>
          <Route
            path="data/:qrCodeData?"
            element={
              <Protected auth={isAuthenticated || token}>
                <Data />
              </Protected>
            }
          />
          {/* <Route
            path="admin"
            element={
              <Protected auth={isAuthenticated || token}>
                <AdminPage />
              </Protected>
            }
          /> */}
          {/* Route for the user's page, where :userId is the URL parameter */}
          <Route path="/user/:userId" element={<UserDetailsPage />} />
          <Route
            path="/reset-password/:id/:token"
            element={<PasswordResetForm />}
          />
          <Route
            path="tenant"
            element={
              <Protected tenantRoute={true} auth={isAuthenticated || token}>
                <Tenant />
              </Protected>
            }
          />
          <Route path="*" element={<Auth />} />
        </Routes>
      </GoogleOAuthProvider>
    );
  else return "...";
}
