import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Protected = ({ auth, children, tenantRoute }) => {
  // const [token, setToken] = useState(undefined);
  const userType = useSelector((state) => state.auth.userType);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/auth");
    }
  });
  if (!tenantRoute && isAuthenticated) return children;
  else if (userType === "tenant") return children;
  else return "no access";
};
export default Protected;
