import { useWretchPost } from "../../hooks/useWretchPost";
import { getConditions, getIndex } from "../../utils/indexes";
import {
  fetchIndoorDataFailure,
  fetchIndoorDataStart,
  fetchIndoorDataSuccess,
} from "../slices/indoorDataSlice";

export const fetchIndoorData = () => async (dispatch) => {
  dispatch(fetchIndoorDataStart());
  const post = useWretchPost(dispatch);
  try {
    let transformedData = [];
    const response = await post(`/device/indoor`);

    response?.message?.forEach((d) => {
      //allergens
      const mold = d?.ml_particle_indices?.mold?.index ?? 0;
      const dander = d?.ml_particle_indices?.dander?.index ?? 0;
      const mites = d?.ml_particle_indices?.mites?.index ?? 0;
      const pollen = d?.ml_particle_indices?.pollen?.index ?? 0;
      //sensors
      const temperature =
        getIndex(d?.sensor_data?.temperature, "temperature") ?? 0;
      const humidity = getIndex(d?.sensor_data?.humidity, "humidity") ?? 0;
      const voc = getIndex(d?.sensor_data?.voc, "voc") ?? 0;
      const co2 = getIndex(d?.sensor_data?.co2, "eco2") ?? 0;

      const allergens = (mites + pollen + dander + mold) / 4;
      const pollution = (voc + co2 + allergens) / 3;
      let indexes = [
        //Transmission = Temperature + Humidity + Allergen + CO2 + VOC
        (temperature + humidity + allergens + co2 + voc) / 5,
        //Stuffiness = Temperature + Humidity + CO2 + VOC
        (temperature + humidity + co2 + voc) / 4,
        //Pollution =  CO2 + VOC + Allergen
        pollution,

        //Humidity
        humidity,

        //Discomfort = Temperature + Humidity + Pollution
        (temperature + humidity + pollution) / 3,
        //Temperature
        temperature,

        //Density
        (voc + co2) / 2,
        //Allergens
        allergens,
      ];
      indexes = indexes.map((i) => parseFloat(i.toFixed(1)));
      if (d?.is_device_online) indexes = indexes.map((i) => (i > 0 ? i : 1));
      transformedData.push({
        ...d,
        condition: getConditions(indexes),
        indexes,
        rawData: { temperature: d?.sensor_data?.temperature ?? "-" },
      });
    });

    dispatch(fetchIndoorDataSuccess(transformedData));
    return transformedData;
  } catch (error) {
    dispatch(fetchIndoorDataFailure(error.toString()));
  }
};
