import { createSlice } from "@reduxjs/toolkit";

export const newConcernsSlice = createSlice({
  name: "newConcerns",
  initialState: {
    value: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchNewConcernsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchNewConcernsSuccess: (state, action) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchNewConcernsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchNewConcernsStart,
  fetchNewConcernsSuccess,
  fetchNewConcernsFailure,
} = newConcernsSlice.actions;

export const selectNewConcerns = (state) => state.newConcerns.value;

export default newConcernsSlice.reducer;
