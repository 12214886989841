export const getIndexName = (index) => {
  if (index < 4) return "Low"
  else if (index < 7) return "Moderate"
  else if (index < 10) return "High"
  else if (index === 10) return "Very high"
  else return null
}

export const getShortIndexName = (index) => {
  if (index < 4) return "Low"
  else if (index < 7) return "Med"
  else if (index < 10) return "High"
  else if (index === 10) return "High"
  else return null
}

export const getIndexColor = (index) => {
  if (index < 4) return "#91C989"
  else if (index < 7) return "#FFC887"
  else if (index < 11) return "#FF7E55"
  else return null
}

export const getIndex = (val, param) => {
  if (isNaN(val) || !val || !param) {

    return 0
  }

  if (param === "temperature") {
    if (val < 11) return 10
    if (val < 16) return 8
    if (val < 20) return 5
    if (val < 25) return 1
    if (val < 30) return 5
    if (val < 35) return 8
    if (val >= 35) return 10
  }

  else if (param === "humidity") {
    if (val < 20) return 10
    if (val < 30) return 8
    if (val < 40) return 5
    if (val < 61) return 1
    if (val < 71) return 5
    if (val < 81) return 8
    if (val > 80) return 10
  }

  else if (param === "eco2") {
    if (val < 500) return 1
    if (val < 600) return 2
    if (val < 750) return 3
    if (val < 900) return 4
    if (val < 1000) return 5
    if (val < 1300) return 6
    if (val < 1500) return 7
    if (val < 1700) return 8
    if (val < 2100) return 9
    if (val > 2099) return 10
  }

  else if (param === "voc") {
    if (val < 84) return 1
    if (val < 168) return 2
    if (val < 251) return 3
    if (val < 835) return 4
    if (val < 1418) return 5
    if (val < 2001) return 6
    if (val < 11335) return 7
    if (val < 20669) return 8
    if (val < 30000) return 9
    if (val > 29999) return 10
  }

  else if (param === "pollen") {
    if (val < 4) return 1
    if (val < 8) return 2
    if (val < 11) return 3
    if (val < 14) return 4
    if (val < 17) return 5
    if (val < 20) return 6
    if (val < 50) return 7
    if (val < 76) return 8
    if (val < 101) return 9
    if (val > 100) return 10
  }

  else if (param === "mold") {
    if (val < 9) return 1
    if (val < 17) return 2
    if (val < 26) return 3
    if (val < 51) return 4
    if (val < 76) return 5
    if (val < 101) return 6
    if (val < 201) return 7
    if (val < 301) return 8
    if (val < 401) return 9
    if (val > 400) return 10
  }


  else if (param === "mites") {

    if (val < 27) return 1
    if (val < 53) return 2
    if (val < 81) return 3
    if (val < 105) return 4
    if (val < 129) return 5
    if (val < 151) return 6
    if (val < 201) return 7
    if (val < 251) return 8
    if (val < 301) return 9
    if (val > 300) return 10
  }


  else if (param === "dander") {

    if (val < 4) return 1
    if (val < 8) return 2
    if (val < 11) return 3
    if (val < 14) return 4
    if (val < 17) return 5
    if (val < 20) return 6
    if (val < 50) return 7
    if (val < 76) return 8
    if (val < 101) return 9
    if (val > 100) return 10
  }
}

export const getConditions = (arr) => {

  if (arr.length === 0) return undefined
  if (arr.some((e) => e > 7)) return 1
  else if (arr.some((e) => e > 3)) return 2
  else if (arr.some((e) => e > 0)) return 3
  else return undefined

}

// export const getChartDataColor = (arr, opacity) => {

//   let op = opacity ? 0.85 : 0.55
//   if (arr.length === 0) return 'transparent'
//   if (arr.some((e) => e > 7)) return `rgb(255, 0, 0, ${op})` //red
//   else if (arr.some((e) => e > 3)) return `rgb(0, 0, 255, ${op})` //blue
//   else if (arr.some((e) => e > 0)) return `rgb(100, 255, 0, ${op})` //green
//   else return 'transparent'

// }


export const getChartDataColor = (no, opacity) => {

  let op = opacity ? 0.85 : 0.85

  if (no === 1) return `rgba(175, 183, 231, ${op})` //blue
  else if (no === 2) return `rgba(255, 178, 117, ${op})` //orange
  else return 'transparent'

}




const getSensorHistoricData = (chartNo, sensor, activeDeviceHistoryData1) => {
  if (!chartNo || !sensor) return null
  if (chartNo === 1 && !activeDeviceHistoryData1?.sensors?.length) return null
  if (chartNo === 2 && !activeDeviceHistoryData2?.sensors?.length) return null
  let source;
  if (chartNo === 1) source = activeDeviceHistoryData1
  if (chartNo === 2) source = activeDeviceHistoryData2
  if (!source || !source?.sensors) return null

  if (sensor === "mold") return source?.sensors?.filter(s => s.sensor_category_id === 21);
  if (sensor === "pollen") return source?.sensors?.filter(s => s.sensor_category_id === 20);
  else return source?.sensors?.filter(s => s.code_name.toLowerCase() === sensor);
}
//datasource is a bad idea
const getIndexArrayForCombinedData = (sensorsArr, dataSource) => {
  try {
    const sumArrays = (...arrays) => {
      const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
      const result = Array.from({ length: n });
      return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
    }
    const elements = []

    sensorsArr.forEach(sensor => {
      if (Array.isArray(sensor)) {
        let sArr = []
        console.log(sensor)
        sensor.forEach(s => {
          if (Array.isArray(s)) {
            let ssArr = []
            s.forEach(ss => {
              ssArr.push(getAverage(getSensorHistoricData(1, ss, dataSource), ss, 'indexArray'))
            })
            sArr.push(ssArr)
          }
          else {
            sArr.push(getAverage(getSensorHistoricData(1, s, dataSource), s, 'indexArray'))
          }
        }
        );
        let result = sArr.reduce((acc, curr) => {
          curr.forEach((num, i) => {
            if (!acc[i] || num > acc[i]) {
              acc[i] = num;
            }
          });
          return acc;
        }, []);

        elements.push(result)
      }
      else {
        elements.push(
          getAverage(getSensorHistoricData(1, sensor, dataSource), sensor, 'indexArray'),
        )
      }
    });

    // console.log(666)
    // console.log('result for', sensorsArr)
    // console.log(sumArrays(...elements).map(val => (val / sensorsArr.length).toFixed()))
    // console.log(666)

    return sumArrays(...elements).map(val => (val / sensorsArr.length).toFixed());
  }
  catch (e) {
    return null
  }
}

export const getAverage = (data, param, type, dataSource) => {
  if (!data || !param) return

  const combinedParamsNames = ['stuffiness', 'density', 'pollution', 'transmission', 'comfort'];

  if (type === "lineData" && dataSource && combinedParamsNames.some((element) => element === param)) {


    // if (param === 'comfort')
    // {
    //   console.log('comfort incoming')
    // }

    switch (param) {
      case 'stuffiness': return getIndexArrayForCombinedData([
        'temperature',
        'humidity',
        'voc',
        'eco2',
      ], dataSource)
  
      case 'density': return getIndexArrayForCombinedData([
        'voc',
        'eco2',
      ], dataSource)

      case 'pollution': return getIndexArrayForCombinedData([
        'voc',
        'eco2',
        ['mold', 'dander', 'mites', 'pollen']
      ], dataSource)
      

      case 'transmission': return getIndexArrayForCombinedData([
        'temperature',
        'humidity',
        'voc',
        'eco2',
        ['mold', 'dander', 'mites', 'pollen']
      ], dataSource)

      case 'comfort': return getIndexArrayForCombinedData([
        'temperature',
        'humidity',
        ['voc',
        'eco2',
       ['mold', 'dander', 'mites', 'pollen']
      ]
      ], dataSource)
      default:
        return null
    }
  }
    let average;
    // if (!fake) {
    if (!data.length) return
    let name = '';
    let results = [];
    let toMerge = [];
    for (const s of data) {
      toMerge.push(s?.data?.value)
      name = s?.code_name

    }
    results = toMerge.reduce(function (a, b) {
      return a?.map(function (v, i) {
        return v + b[i];
      });
    });
    const withoutEmpty = results?.filter(val => val !== 0.0 && val !== null);
    const sum = results?.reduce((a, b) => a + b, 0);
    if (withoutEmpty?.length === 0) average = 0
    else average = sum / withoutEmpty?.length;
    // }

    let indexArray = [];
    if (type === 'indexArray') {
      results.forEach(element => {
        indexArray.push(getIndex(element, param))
      });
    }



    //else average = (data.reduce((a, b) => parseInt(a) + parseInt(b), 0) / data.length).toFixed();
    if (type === 'lineData') return results
    if (type === 'indexArray') return indexArray
    else return getIndex(average, param);

}