import logo from "./logo.svg";
import { useGeolocated } from "react-geolocated";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemText, ListItemButton, Collapse } from "@mui/material";
import { SLoader } from "./components/sLoader/sLoader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { shareOnMobile } from "react-mobile-share";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CircularProgress } from "@mui/material";
import { API_URL } from "./consts";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import styles from "./tenant.module.css";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useWretchGet } from "./hooks/useWretchGet";
import { useWretchPost } from "./hooks/useWretchPost";
import { useDispatch } from "react-redux";

function TabPanel(props) {
  const dispatch = useDispatch();
  const get = useWretchGet(dispatch);
  const post = useWretchPost(dispatch);
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "500px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="generate qr codes" {...a11yProps(0)} />
          <Tab label="add new user" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ChooseFleet qrCode />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChooseFleet addUser />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
  );
}

const ChooseFleet = ({ qrCode, addUser }) => {
  const [fleet, setFleet] = useState(54);
  const [fleets, setFleets] = useState([]);

  const handleFleetChange = (event) => {
    console.log(event);
    console.log(event.target.value);
    setFleet(event.target.value);
  };

  const getFleets = async () => {
    get(`/device/fleets`);
  };

  useEffect(() => {
    getFleets();
  }, []);

  if (!fleets?.length)
    return (
      <>
        <CircularProgress />
      </>
    );
  else
    return (
      <Box sx={{ width: "300px" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Fleet</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fleet}
            label="fleet"
            onChange={handleFleetChange}
          >
            {fleets?.map((fleet, idx) => {
              return (
                <MenuItem key={fleet?.id} value={fleet?.id}>
                  {fleet?.fleet_name} ({fleet?.company_name})
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <ShowQrCodes fleet={fleet} addUser={addUser} />
      </Box>
    );
};

const ShowQrCodes = ({ fleet, addUser }) => {
  const [qrCodes, setqrCodes] = useState([]);
  const [showQrCode, setShowQrCode] = useState(null);
  const [loading, setLoading] = useState(null);
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [customQr, setCustomQr] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);

  const getQr = async (fleetId) => {
    get(`/tenant/qr/${fleetId}`);
  };

  // useEffect(() => {
  //   setLoading(true);
  //   getQr(fleet);
  // }, [fleet]);

  const validate = (values) => {
    let errors = false;

    if (!values) {
      errors = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      errors = "Invalid email address";
    }

    return errors;
  };

  const registerUser = async () => {
    if (!userPassword && !userPassword) alert("complete the required fields");
    post(`/auth/register`, {
      email: userEmail,
      password: userPassword,
      devices: checkedDevices,
    });
  };

  if (!fleet) return null;
  else if (qrCodes?.length < 1 || loading)
    return (
      <div>
        <br />
        <List>
          <ListItemButton>
            <CircularProgress />
          </ListItemButton>
        </List>
      </div>
    );
  else
    return (
      <div>
        {addUser ? (
          <>
            <TextField
              sx={{ marginTop: "30px" }}
              error={
                userEmail?.length ? (validate(userEmail) ? true : false) : false
              }
              id="outlined-controlled"
              label={
                userEmail?.length
                  ? validate(userEmail)?.length
                    ? validate(userEmail)
                    : "Email address"
                  : "Email address"
              }
              value={userEmail}
              onChange={(event) => {
                setUserEmail(event.target.value);
              }}
              placeholder="Email"
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
            />

            <TextField
              sx={{ marginTop: "30px", marginBottom: "30px" }}
              id="outlined-controlled2"
              label={"password"}
              value={userPassword}
              onChange={(event) => {
                setUserPassword(event.target.value);
              }}
              placeholder="password"
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
            />
          </>
        ) : null}

        <Button
          variant="outlined"
          onClick={() => {
            if (!checkedDevices || checkedDevices.length === 0) {
              alert("no device was selected");
            }

            addUser
              ? registerUser(checkedDevices)
              : post(`/tenant/getCustomQr`, {
                  devices: checkedDevices,
                  company: fleet,
                });
          }}
        >
          {addUser ? "create user" : " generate QR for selected devices"}
        </Button>

        {customQr && !addUser ? (
          <div style={{ textAlign: "center" }}>
            <img
              onClick={async () =>
                shareOnMobile({
                  title: customQr?.name,
                  text: customQr?.serialNumber,
                  url: customQr?.url,
                  images: [
                    await getBase64FromUrl(
                      `${API_URL}/public/qr/${customQr?.fileName}`
                    ),
                  ],
                })
              }
              src={`${API_URL}/public/qr/${customQr?.fileName}`}
            />
            <div style={{ fontSize: 10, top: -15, position: "relative" }}>
              click to share
            </div>
          </div>
        ) : null}

        <List>
          {qrCodes?.map((qr, idx) => {
            return (
              <span key={qr?.name}>
                <div style={{ display: "inline-flex" }}>
                  <Checkbox
                    checked={checkedDevices.includes(qr?.serialNumber)}
                    onChange={() =>
                      checkedDevices.includes(qr?.serialNumber)
                        ? setCheckedDevices(
                            checkedDevices.filter(
                              (item) => item !== qr?.serialNumber
                            )
                          )
                        : setCheckedDevices((old) => [...old, qr?.serialNumber])
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <ListItemButton
                    onClick={() =>
                      addUser
                        ? null
                        : setShowQrCode(
                            showQrCode === qr?.name ? null : qr?.name
                          )
                    }
                  >
                    <span
                      style={{ color: qr?.hasThing ? "black" : "lightgrey" }}
                    >
                      {qr?.name} / {qr?.serialNumber}
                    </span>
                  </ListItemButton>
                </div>
                <Collapse
                  in={showQrCode === qr?.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      onClick={async () =>
                        shareOnMobile({
                          title: qr?.name,
                          text: qr?.serialNumber,
                          url: qr?.url,
                          images: [
                            await getBase64FromUrl(
                              `${API_URL}/public/qr/${qr?.fileName}`
                            ),
                          ],
                        })
                      }
                      src={`${API_URL}/public/qr/${qr?.fileName}`}
                    />
                    <div
                      style={{ fontSize: 10, top: -15, position: "relative" }}
                    >
                      click to share
                    </div>
                  </div>
                </Collapse>
              </span>
            );
          })}
        </List>
      </div>
    );
};

const Tenant = () => {
  return (
    <div>
      <BasicTabs />
    </div>
  );
};

export default Tenant;

/*

what needs to be passed to api

* iv
* content
* phone number
* geo location


*/
