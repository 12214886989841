let API_URL = `https://monitor.sensioair.com/api`;

if (process.env.REACT_APP_MODE == "development") {
  API_URL = `https://dev-monitor.sensioair.com/api`;
}

if (process.env.REACT_APP_MODE == "qa") {
  API_URL = `https://qa-monitor.sensioair.com/api`;
}

if (process.env.REACT_APP_MODE == "local") {
  API_URL = `http://localhost:3337/api`;
}

if (process.env.REACT_APP_API_URL) {
  API_URL = process.env.REACT_APP_API_URL;
}

const PUBLIC_URL = API_URL + "/public/";

const APPLE_LOGIN_LINK = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=com.sensioair.monitor&redirect_uri=
${API_URL}/auth/login/apple&scope=name%20email&response_mode=form_post`;

const APPLE_REGISTER_LINK = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=com.sensioair.monitor&redirect_uri=
${API_URL}/auth/register/apple&scope=name%20email&response_mode=form_post`;

export { API_URL, PUBLIC_URL, APPLE_LOGIN_LINK, APPLE_REGISTER_LINK };
