import React from "react";
import styles from "./BarIndicator.module.css";
import { getBackgroundColor } from "./BigIndexBox";
import { LinearProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export function BarIndicator({
  value,
  type,
  rawValue,
  isMobileView,
  isPhoneView,
  testViewWidth,
  testViewHeight,
  testViewHeight2048,
  GAV1200,
  GAV3840,
  onValueClick = () => {},
}) {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "grey",
      boxShadow: theme.shadows[1],
      fontSize: GAV3840 ? 27 : 13,
      maxWidth: isMobileView
        ? "77vw"
        : testViewHeight && testViewWidth
        ? testViewHeight2048
          ? "41.2vh"
          : "44.2vh"
        : GAV3840
        ? "76vw"
        : "36vw",
      top: 8,
      marginTop: "10px !important",
    },
  }));

  const getDesc = (param) => {
    switch (param) {
      case "Pollution":
        return "Indoor pollution refers to the presence of harmful substances within a building or enclosed space, such as chemicals, allergens, and particulate matter. These pollutants can have negative effects on health and comfort.";
      case "Temperature":
        return "Temperature refers to the degree of heat or cold in a space. Temperature can affect comfort levels and can also affect the concentration of pollutants such as VOCs.";
      case "Discomfort":
        return "Indoor comfort refers to the overall well-being and satisfaction of the people in a space, taking into account factors such as temperature, humidity, and air quality. Improving indoor comfort can involve reducing pollutants and increasing ventilation, among other measures.";
      case "Stuffiness":
        return "Air stuffiness refers to the feeling of discomfort caused by stagnant, humid air that can lead to a buildup of pollutants and allergens.";
      case "Transmission":
        return "Air transmission refers  to the dispersal of pollutants, allergens and pathogens in the air. It takes into consideration mutliple factors including density, humidity";
      default:
        return null;
    }
  };

  // (If true) Show discription under BarIndicator
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [width, setWidth] = React.useState(false);
  const widthRef = React.useRef();
  const theme = createTheme({
    palette: {
      custom: {
        main: getBackgroundColor(value / 10)?.index,
      },
    },
  });

  React.useEffect(() => {
    setWidth(widthRef?.current?.clientWidth);
  });

  return (
    <div className={`${styles.container}`}>
      <div
        onClick={onValueClick}
        className={`${styles.left}`}
        style={{
          padding: type === `Temperature` ? "0 5px" : undefined,
          color: getBackgroundColor(value / 10)?.index,
          fontSize: isPhoneView
            ? "2.4vh"
            : testViewHeight && testViewWidth
            ? "2.1vh"
            : "",
        }}
      >
        {rawValue ? rawValue : !isNaN(value) ? parseInt(value / 10) + "" : "-"}
      </div>
      <div
        className={`${styles.right}`}
        style={{
          fontSize: testViewHeight && testViewWidth ? "1.8vh" : undefined,
        }}
      >
        <div ref={widthRef}>{type}</div>
        <ThemeProvider theme={theme}>
          <LinearProgress
            color={"custom"}
            variant="determinate"
            value={parseInt(value) || 0}
          />
        </ThemeProvider>
        <CustomTooltip
          onClose={() => setTooltipOpen(false)}
          open={tooltipOpen}
          title={getDesc(type)}
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: width ? "nowrap" : "wrap",
              alignSelf: "start",
              color: "grey",
              width: GAV1200
                ? "76vw"
                : isMobileView
                ? "77vw"
                : testViewHeight && testViewWidth
                ? testViewHeight2048
                  ? "41.2vh"
                  : "44.2vh"
                : "36vw",
              margin: 0,
              fontSize:
                testViewHeight && testViewWidth ? 20 : GAV3840 ? 27 : 13,
            }}
            onClick={() => setTooltipOpen(true)}
          >
            {width ? getDesc(type) : null}
          </span>
        </CustomTooltip>
      </div>
    </div>
  );
}
