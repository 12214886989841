import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
// REDUX STORE
import { store } from "./store";
import axios from "axios";
import { API_URL } from "./consts";

// Function for sending errors to the server
const sendErrorToServer = async (error) => {
  try {
    // Sending a request to the server to handle the error
    const response = await axios.post(`${API_URL}/status/client`, {
      error,
    });
    console.log("Error sent to the server:", response.data);
  } catch (error) {
    console.error("Error sending an error to the server:", error);
  }
};

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  // Send the error to the server
  sendErrorToServer(error);

  return true;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <meta name="theme-color" content="white" />
      </Helmet>
      <App />
    </BrowserRouter>
  </Provider>
);
