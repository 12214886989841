import { useWretchGet } from "../../hooks/useWretchGet";
import {
  fetchIotDataFailure,
  fetchIotDataStart,
  fetchIotDataSuccess,
} from "../slices/iotDataSlice";

export const fetchIotData = () => async (dispatch) => {
  dispatch(fetchIotDataStart());
  const get = useWretchGet(dispatch);
  try {
    const response = await get(`/device/iot`);
    if (!response?.error) {
      dispatch(fetchIotDataSuccess(response?.message));
    } else {
      dispatch(fetchIotDataFailure("Failed to fetch IoT data."));
    }
  } catch (error) {
    dispatch(fetchIotDataFailure(error.toString()));
  }
};
