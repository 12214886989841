import {
  fetchConcernsFailure,
  fetchConcernsStart,
  fetchConcernsSuccess,
} from "../slices/concernsSlice";
import { useWretchGet } from "../../hooks/useWretchGet";

export const fetchConcerns =
  (userToken, cleanCleaningRequest, callback, dispatch) => async () => {
    dispatch(fetchConcernsStart());
    const get = useWretchGet(dispatch);
    try {
      const response = await get(`/concerns`);

      dispatch(fetchConcernsSuccess(response));
      if (callback) {
        callback(cleanCleaningRequest); // Call the callback with cleanCleaningRequest as an argument
      }
    } catch (error) {
      dispatch(fetchConcernsFailure(error.toString()));
      if (callback) {
        callback(cleanCleaningRequest);
      }
    }
  };
