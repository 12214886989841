import { createSlice } from "@reduxjs/toolkit";

export const hiddenConcernSlice = createSlice({
  name: "hiddenConcern",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    hideConcernStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    hideConcernSuccess: (state) => {
      state.loading = false;
    },
    hideConcernFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { hideConcernStart, hideConcernSuccess, hideConcernFailure } =
  hiddenConcernSlice.actions;

export default hiddenConcernSlice.reducer;
