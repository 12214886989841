import { createSlice } from "@reduxjs/toolkit";

export const concernsSlice = createSlice({
  name: "concerns",
  initialState: {
    value: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchConcernsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchConcernsSuccess: (state, action) => {
      state.value = action.payload;
      state.loading = false;
    },
    fetchConcernsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchConcernsStart,
  fetchConcernsSuccess,
  fetchConcernsFailure,
} = concernsSlice.actions;

export const selectConcerns = (state) => state.concerns.value;

export default concernsSlice.reducer;
