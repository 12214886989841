import React, { useState } from "react";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import "./ReportConcern.css";
import "./index.css";
import { useWretchPost } from "../../hooks/useWretchPost";
import { useDispatch } from "react-redux";
import ImageModalPopap from "../ImageModalPopap";

function ReportConcern({
  width = "100%",
  height = "110px",
  deviceName,
  deviceSerial,
  setLearnMore,
  setShowModal,
  setModalType,
  setSuccessSnackbarOpen,
  setLoadingProcess,
  loadingProcess,
  successSnackbarOpen,
  ...props
}) {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [textareaData, setTextareaData] = useState(null);
  const [imagesToUpload, setImagesToUpload] = useState({ cachedFileArray: [] });

  // Function for adding new images
  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files) {
      setImagesToUpload((prevState) => ({
        ...prevState,
        cachedFileArray: [...prevState.cachedFileArray, ...files],
      }));
    }
  };

  // Function for sending a form with data to the server.
  const uploadForm = async () => {
    setLoadingProcess(true);
    const post = useWretchPost(dispatch);
    try {
      const response = await post(
        `/concerns`,
        {
          files: imagesToUpload?.cachedFileArray,
          text: textareaData,
          deviceName,
          deviceSerial,
          type: "concern",
        },
        {},
        true
      );
      if (response.error === false) {
        setLoadingProcess(false);
        setSuccessSnackbarOpen(true);
        setLearnMore(false);
        setShowModal(false);
        setModalType(null);
      }
    } catch (error) {
      setLoading(false);
      console.log("ERROR:", error);
      console.error("An error occurred while uploading the form:", error);
    }

    // wretchInstance(`/concerns`)
    //   .addon(FormDataAddon)
    //   .formData({
    //     files: imagesToUpload?.cachedFileArray,
    //     text: textareaData,
    //     deviceName,
    //     deviceSerial,
    //     type: "concern",
    //   })
    //   .post()
    //   .unauthorized((error) => {
    //     console.log(error);
    //   })
    //   .json((json) => {
    //     // console.log(json);
    //     props.uploadRes(json);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // Function to delete an image
  const handleDelete = (event, indexToDelete) => {
    event.stopPropagation(); // This stops the event from popping up
    const updatedImages = imagesToUpload.cachedFileArray.filter(
      (_, index) => index !== indexToDelete
    );
    setImagesToUpload({
      ...imagesToUpload,
      cachedFileArray: updatedImages,
    });
  };

  return (
    <>
      {/* Modal window for displaying the active image */}
      {isModalOpen && (
        <ImageModalPopap
          activeImage={activeImage}
          setModalOpen={setModalOpen}
        />
      )}
      {/* Text field for entering a message */}
      <div className="textarea-container">
        <textarea
          className="custom-textarea"
          onChange={(e) => setTextareaData(e?.target?.value)}
        />
      </div>
      {/* Container for the button that loads the image and for the images themselves*/}
      <div className="image-upload-container">
        {/* Images that have been uploaded */}
        <div className="uploaded-images-container">
          {imagesToUpload?.cachedFileArray.map((image, index) => (
            <div
              key={index}
              className="uploaded-image"
              style={{
                backgroundImage: `url(${URL.createObjectURL(image)})`,
              }}
              onClick={() => {
                setActiveImage(URL.createObjectURL(image));
                setModalOpen(true);
              }}
            >
              <button
                className="modal-close-btn"
                onClick={(event) => handleDelete(event, index)}
              >
                X
              </button>
            </div>
          ))}
        </div>

        {/* Button for adding new images */}
        <div className="upload-control-container">
          <div
            className={`upload-button ${
              imagesToUpload.cachedFileArray.length >= 3
                ? "disabled-input"
                : null
            }`}
          >
            +
            <input
              type="file"
              onChange={handleImageUpload}
              multiple
              disabled={imagesToUpload.cachedFileArray.length >= 3}
              className="image-upload-input"
            />
          </div>
        </div>
      </div>

      <div className="submit-button" onClick={() => uploadForm()}>
        Submit Form
      </div>
    </>
  );
}
export default ReportConcern;
