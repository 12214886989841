import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { LinearProgress, CircularProgress } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { SignalCellularNullOutlined } from "@mui/icons-material";
import "./BigButton.css";
const buttons = [{}];

export const backgroundPalette = [
  "lightgrey",
  "#E7615E",
  "#FFC161", //orange
  "#4FB340", //green
  "#4059D4", //blue
];

export const colors = {
  white: "white",
};

export default function BigButton({
  disabled,
  color = "white",
  icon,
  title,
  onClick,
  fontSize = 16,
  backgroundColor = 1,
  loading,
  height = 84,
  borderRadius = 20,
  width = "100%",
}) {
  const smallCleaningRequest = color === "black";

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "space-around",
    color: theme.palette.common.white,
    alignItems: smallCleaningRequest ? "self-end" : "center",
  }));

  const ImageBackdrop = styled("span")(({ theme, backgroundColor }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius,
    backgroundColor: backgroundColor,
    //   opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    //   position: 'relative',
    //   height: 200,
    //   [theme.breakpoints.down('sm')]: {
    //     width: '100% !important', // Overrides inline-style
    //     height: 100,
    //   },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: borderRadius === 3 ? 1 : loading ? 1 : 0.9,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        //   border: '4px solid currentColor',
      },
    },
  }));

  return (
    <>
      {buttons.map((b) => (
        <ImageButton
          disabled={disabled || loading}
          onClick={onClick}
          focusRipple
          key={title}
          disableRipple
          disableTouchRipple
          style={{
            width: "100%",
            height,
            display: "flex",
            margin: "auto",
            width,
            opacity: loading ? 0.5 : disabled ? 0.25 : 1,
            cursor: loading ? "wait" : undefined,
          }}
        >
          {/* <ImageSrc style={{ backgroundImage: `url(${image.url})` }} /> */}

          {loading ? (
            <CircularProgress
              size={20}
              sx={{ color: "white!important", zIndex: 1 }}
            />
          ) : null}

          <ImageBackdrop
            backgroundColor={
              isNaN(backgroundColor)
                ? backgroundColor
                : backgroundPalette[backgroundColor]
            }
            className="MuiImageBackdrop-root"
          />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                fontSize,
                color,
                bottom: smallCleaningRequest ? 5 : 0,
              }}
            >
              {title}
              {/* <ImageMarked className="MuiImageMarked-root" /> */}
            </Typography>
            <span
              style={{
                position: "absolute",
                right: 20,
                top: smallCleaningRequest ? 5 : undefined,
              }}
            >
              {icon ?? null}
            </span>
          </Image>
        </ImageButton>
      ))}
    </>
  );
}
