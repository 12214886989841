import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Paper,
  Alert,
  Switch,
  Button,
  Backdrop,
  TableRow,
  Snackbar,
  TableHead,
  TextField,
  TableBody,
  TableCell,
  useMediaQuery,
  TableContainer,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { fetchUsers } from "../store/thunk/usersThunks";
import "./AdminPage.css";
import { useNavigate } from "react-router-dom";
import CachedInfoPopup from "../components/CachedInfoPopup";
import CreateUserPopup from "../components/CreateUserPopup";
import { useWretchPost } from "../hooks/useWretchPost";
import { useWretchPut } from "../hooks/useWretchPut";
import { useWretchDelete } from "../hooks/useWretchDelete";

/**
 * - Show all users from the database;
 * - Edit user fields;
 * - Delete a user;
 * - Create a user;
 * - Manage cached data (clear / reset);
 */

const AdminPage = () => {
  // Determine if it is a mobile device
  const isMobile = useMediaQuery("(max-width: 950px)");
  const dispatch = useDispatch();
  const post = useWretchPost(dispatch);
  const put = useWretchPut(dispatch);
  const Delete = useWretchDelete(dispatch);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  // Loading users into the table
  const users = useSelector((state) => state.users.usersList || {});

  //////////////////////////////////////////////////////////////////////////////////
  // Token management
  const [manageTokenTime, setManageTokenTime] = useState(false);
  const [expiryTime, setExpiryTime] = useState(0);
  const [cookieExpiryTime, setcookieExpiryTime] = useState(0);

  // Token expiry time
  const handleExpiryChange = (event) => {
    console.log(event.target.value);
    setExpiryTime(event.target.value);
  };

  // Cookie expiry time
  const handleCookieExpiryChange = (event) => {
    console.log(event.target.value);
    setcookieExpiryTime(event.target.value);
  };

  // Function for opening a popup
  const openManagePopup = () => {
    setManageTokenTime(true);
  };

  // Function for closing the popup
  const closeManagePopup = () => {
    setManageTokenTime(false);
  };

  const handleManageTokenSave = async () => {
    setLoading(true);
    const data = {
      expiryTime: expiryTime,
      cookieExpiryTime: cookieExpiryTime,
    };
    try {
      await put("/tokens/editTokenTime", { data });
      setLoading(false);
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating Token Expiry Time:", error);
      setLoading(false);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////
  // Functionality of opening, closing Cached Info Popup and clear / reset varios
  const [cachedInfoPopupOpen, setCachedInfoPopupOpen] = useState(false);
  const [resetButtonClicked, setResetButtonClicked] = useState(false);

  // Function for opening a popup
  const openCachedInfoPopup = () => {
    setCachedInfoPopupOpen(true);
  };

  // Function for closing the popup
  const closeCachedInfoPopup = () => {
    setCachedInfoPopupOpen(false);
  };

  //////////////////////////////////////////////////////////////////////////////////
  // Functionality of opening, closing POPUP and creating a new user
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function for opening a popup
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  // Function for closing the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  //////////////////////////////////////////////////////////////////////////////////
  // Function for calculating the height of the pop-up
  const [emailError, setEmailError] = useState(""); // Add state for email error
  const [passwordError, setPasswordError] = useState(""); // Add state for password error
  const [calculateHeight, setCalculateHeight] = useState();

  useEffect(() => {
    const calculateHeight = () => {
      let height = null;
      if (emailError !== "") {
        height = 290;
        if (passwordError !== "") {
          height = 330;
        }
      } else if (passwordError !== "") {
        height = 310;
      }
      setCalculateHeight(height);
    };
    calculateHeight();
  }, [emailError, passwordError]);

  //////////////////////////////////////////////////////////////////////////////////
  // Enter the editing mode
  const handleEditUser = (user) => {
    navigate(`/user/${user._id}`, {
      state: {
        user,
      },
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////
  // Function of removing a user from the database
  const handleDeleteUser = async (userId) => {
    setLoading(true);
    try {
      const response = await Delete(`/user/deleteUser/${userId}`);
      if (response.error == false) {
        setLoading(false);
        setSuccessSnackbarOpen(true);
        dispatch(fetchUsers({ dispatch }));
      }
    } catch (error) {
      // Handling errors during deletion
      console.error("Error deleting user:", error);
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  // Reset user password
  const handleResetPassword = async (email) => {
    setLoading(true);
    try {
      await post(`/auth/reset-password`, { email: email });
      setLoading(false);
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.log("Reset user password Error");
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  // Call thunk to get users when the page loads
  useEffect(() => {
    dispatch(fetchUsers({ dispatch }));
    setResetButtonClicked(false);
  }, [dispatch, resetButtonClicked]);

  return (
    <div
      className="popup-container"
      style={{ overflowY: "auto", height: "100vh" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          Successful operation!
        </Alert>
      </Snackbar>
      <div
        className="page-content"
        style={{ width: isMobile ? "100%" : "50%" }}
      >
        {/* Header container  */}
        <div className="header">
          <div>
            <div className="header-title">ADMIN PANEL</div>
            <div className="header-subtitle">Users list</div>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={openManagePopup}
              style={{ height: 24, fontSize: 10, textTransform: "none" }}
            >
              Manage "TokenExpiryTime"
            </Button>
            {/* Expiry time */}
            {manageTokenTime && (
              <div className="popup">
                <div
                  className="popup-content"
                  style={{
                    height: 340,
                  }}
                >
                  {/* Closed popup */}
                  <span className="close-button" onClick={closeManagePopup}>
                    &times;
                  </span>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "16px" }}
                  >
                    <h5>Token Expiry Time</h5>
                    <Select
                      style={{ width: "100%", height: 50 }}
                      value={expiryTime}
                      onChange={handleExpiryChange}
                      label="Token Expiry Time"
                    >
                      <MenuItem value={0}>Empty</MenuItem>
                      <MenuItem value={`1_week`}>1 Week</MenuItem>
                      <MenuItem value={`2_weeks`}>2 Weeks</MenuItem>
                      <MenuItem value={`1_month`}>1 Month</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" onClick={handleManageTokenSave}>
                    Apply Changes
                  </Button>

                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: "16px" }}
                  >
                    <h5>Cookie Expiry Time</h5>
                    <Select
                      style={{ width: "100%", height: 50 }}
                      value={cookieExpiryTime}
                      onChange={handleCookieExpiryChange}
                      label="Cookie Expiry Time"
                    >
                      <MenuItem value={0}>Empty</MenuItem>
                      <MenuItem value={`1_week`}>1 Week</MenuItem>
                      <MenuItem value={`2_weeks`}>2 Weeks</MenuItem>
                      <MenuItem value={`1_month`}>1 Month</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={handleManageTokenSave}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    Apply Changes
                  </Button>
                </div>
              </div>
            )}
            {/* // Cached data list block container  */}
            <Button
              variant="contained"
              onClick={openCachedInfoPopup}
              style={{
                marginLeft: 10,
                height: 24,
                fontSize: 10,
                textTransform: "none",
              }}
            >
              Manage cached data
            </Button>
            {cachedInfoPopupOpen && (
              <CachedInfoPopup
                resetButtonClicked={resetButtonClicked}
                closeCachedInfoPopup={closeCachedInfoPopup}
                setResetButtonClicked={setResetButtonClicked}
              />
            )}
            {/* // --------------------------------  */}
            {/* // Create new user block container  */}
            <Button
              variant="contained"
              onClick={openPopup}
              style={{
                marginLeft: 10,
                height: 24,
                padding: "4px 10px",
                fontSize: 10,
                textTransform: "none",
              }}
            >
              Create new user
            </Button>
            {/* Create user Popup */}
            {isPopupOpen && (
              <CreateUserPopup
                closePopup={closePopup}
                calculateHeight={calculateHeight}
                emailError={emailError}
                passwordError={passwordError}
                setLoading={setLoading}
                loading={loading}
                setSuccessSnackbarOpen={setSuccessSnackbarOpen}
                successSnackbarOpen={successSnackbarOpen}
              />
            )}
          </div>
        </div>
        {/* Main container */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Email Verified</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  {/* Email */}
                  <TableCell>{user.email}</TableCell>
                  {/* Email Verified */}
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: 10 }}></div>
                      <Switch
                        disabled
                        checked={
                          user.emailVerified === "true" ||
                          user.emailVerified === true
                        }
                        size="small"
                      />
                    </div>
                  </TableCell>
                  {/* Actions */}
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginBottom: 10 }}
                        fullWidth
                        onClick={() => handleResetPassword(user.email)}
                      >
                        Reset password
                      </Button>

                      <Button
                        sx={{ marginBottom: 1 }}
                        variant="contained"
                        onClick={() => handleEditUser(user)}
                      >
                        Edit User
                      </Button>

                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff00009c",
                          fontWeight: 700,
                        }}
                        onClick={() => handleDeleteUser(user._id)}
                      >
                        Delete User
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AdminPage;
